import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik, FieldArray } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import DayCheckBox from "./DayCheckbox";

import {
  getZones as onGetZones,
  addNewZone as onAddNewZone,
  updateZone as onUpdateZone,
  deleteZone as onDeleteZone,
  getCities as onGetCities,
} from "store/actions";


//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Badge
} from "reactstrap";
import moment from "moment";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { DeliveryDays } from "constants/AppConstants";

function Zone() {

  //meta title
  document.title = "Master >> Zones";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [zone, setZone] = useState(null);
  const [deliveryDays, setDeliveryDays] = useState([]);




  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: (zone && zone.status) || '',
      name: (zone && zone.name) || '',
      city: (zone && zone.city) || '',
      deliveryFee: (zone && zone.deliveryFee) || '',
      // pincodes: zone?.pincodes || "" //Node version 16 syntax conflict: optional chaining not supported.
      pincodes: zone ? zone.pincodes || "" : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Zone Name"),
      city: Yup.string().required("Please Select City"),
      deliveryFee: Yup.string().required("Please Enter Delivery Fee"),
      status: Yup.string().required("Please Enter Status"),
      pincodes: Yup.string().required("Please enter comma separate pincodes")
    }),
    onSubmit: (values) => {
      if (!deliveryDays || (deliveryDays && deliveryDays.length == 0)) {
        return;
      }

      if (isEdit) {
        const updateZone = {
          _id: zone ? zone._id : '',
          status: values.status == 'true' ? true : false,
          name: values.name,
          city: values.city,
          deliveryFee: values.deliveryFee,
          deliveryDays: deliveryDays,
          pincodes: values.pincodes
        };
        // update order
        dispatch(onUpdateZone(updateZone));
        validation.resetForm();
      } else {

        const newZone = {
          status: values["status"] == "true" ? true : false,
          name: values["name"],
          city: values["city"],
          deliveryFee: values["deliveryFee"],
          deliveryDays: deliveryDays,
          pincodes: values.pincodes
        };
        // save new order
        dispatch(onAddNewZone(newZone));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  const selectZoneState = (state) => state.Zone;
  const ZoneProperties = createSelector(
    selectZoneState,
    (Zone) => ({
      zones: Zone.zones
    })
  );

  const { zones } = useSelector(ZoneProperties);

  const selectCityState = (state) => state.City;
  const CityProperties = createSelector(
    selectCityState,
    (City) => ({
      cities: City.cities
    })
  );

  const { cities } = useSelector(CityProperties);


  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(onGetZones());
    dispatch(onGetCities({ status: true }));

  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(zones) && !!isEdit) {
      setIsEdit(false);
    }
  }, [zones]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setZone(null);
    } else {
      setModal(true);
    }
  };

  const handleZoneClick = arg => {
    const zone = arg;
    setZone({
      _id: zone._id,
      status: '' + zone.status,
      name: zone.name,
      city: zone.city?._id,
      deliveryFee: zone.deliveryFee,
      pincodes: zone && zone.pincodes && zone.pincodes.length > 0 && zone.pincodes.join(", ")
    });

    setDeliveryDays(zone.deliveryDays);

    setIsEdit(true);

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (zone) => {
    setZone(zone);
    setDeleteModal(true);
  };

  const handleDeleteZone = () => {
    if (zone && zone._id) {
      dispatch(onDeleteZone(zone._id));
      setDeleteModal(false);
    }
  };
  const handleZoneClicks = () => {
    setIsEdit(false);
    setDeliveryDays([]);
    toggle();
  };

  const changeDeliveryDays = (day) => {
    day = parseInt(day);
    let days = [...deliveryDays];
    const index = days.indexOf(day);
    if (index > -1) {
      days.splice(index, 1);
    }
    else {
      days.push(day);
    }
    setDeliveryDays(days);
  }

  const cleanPinCodes = (pincodeArray) => {
    if (!pincodeArray || !Array.isArray(pincodeArray)) {
      return "";
    }
    const cleanedPincodes = pincodeArray.map(pincode => pincode.trim());
    return cleanedPincodes.join(", ");
  };

  const columns = useMemo(
    () => [
      {
        Header: () => <div className="form-check font-size-16" >
          <input className="form-check-input" type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.name}
          </>;
        }
      },
      {
        Header: 'Delivery Fee',
        accessor: 'deliveryFee',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.deliveryFee}
          </>;
        }
      },
      {
        Header: 'City',
        accessor: 'city',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.city?.name}
          </>;
        }
      },
      {
        Header: 'Pincodes',
        accessor: 'pincodes',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          const rawPincodes = cellProps?.row?.original?.pincodes;
          const cleanedPincodes = cleanPinCodes(rawPincodes);
          return <>{cleanedPincodes}</>;
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.status ? 'Active' : 'Inactive'}
          </>;
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const zoneData = cellProps.row.original;
                  handleZoneClick(zoneData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteZone}
        onCloseClick={() => setDeleteModal(false)}
        title="Zone"
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Masters" breadcrumbItem="Zones" />
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>

                      <TableContainer
                        columns={columns}
                        title="Zone"
                        data={zones}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        handleOrderClicks={handleZoneClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
          <Modal size="lg" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Zone" : "Add Zone"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col col={6}>
                    <div className="mb-3">
                      <Label>Zone Name</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Zone Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ''}
                        invalid={
                          validation.touched.name && validation.errors.name ? true : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col col={6}>
                    <div className="mb-3">
                      <Label>City</Label>
                      <Input
                        name="city"
                        type="select"
                        placeholder="City"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.city || ''}
                        invalid={
                          validation.touched.city && validation.errors.city ? true : false
                        }
                      >
                        <option value="">Select City</option>
                        {cities.map((city) => (
                          <option key={city._id} value={city._id}>{city.name}</option>
                        ))}
                      </Input>
                      {validation.touched.city && validation.errors.city ? (
                        <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                      ) : null}
                    </div>

                  </Col>
                </Row>

                <Row>
                  <Col col={6}>
                    <div className="mb-3">
                      <Label>Delivery Fee</Label>
                      <Input
                        name="deliveryFee"
                        type="text"
                        placeholder="Delivery Fee"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.deliveryFee || ''}
                        invalid={
                          validation.touched.deliveryFee && validation.errors.deliveryFee ? true : false
                        }
                      />
                      {validation.touched.deliveryFee && validation.errors.deliveryFee ? (
                        <FormFeedback type="invalid">{validation.errors.deliveryFee}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col col={6}>
                    <div className="mb-3">
                      <Label>Status</Label>
                      <Input
                        name="status"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status}

                      >
                        <option>Select Status</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.status}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Delivery Days</Label>

                      <Row>
                        <Col>
                          <DayCheckBox
                            day={DeliveryDays.MONDAY}
                            label="Mon"
                            data={deliveryDays}
                            onChange={changeDeliveryDays}
                          />
                        </Col>
                        <Col>
                          <DayCheckBox
                            day={DeliveryDays.TUESDAY}
                            label="Tue"
                            data={deliveryDays}
                            onChange={changeDeliveryDays}
                          />
                        </Col>
                        <Col>
                          <DayCheckBox
                            day={DeliveryDays.WEDNESDAY}
                            label="Wed"
                            data={deliveryDays}
                            onChange={changeDeliveryDays}
                          />
                        </Col>
                        <Col>
                          <DayCheckBox
                            day={DeliveryDays.THURSDAY}
                            label="Thu"
                            data={deliveryDays}
                            onChange={changeDeliveryDays}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DayCheckBox
                            day={DeliveryDays.FRIDAY}
                            label="Fri"
                            data={deliveryDays}
                            onChange={changeDeliveryDays}
                          />
                        </Col>

                        <Col>
                          <DayCheckBox
                            day={DeliveryDays.SATURDAY}
                            label="Sat"
                            data={deliveryDays}
                            onChange={changeDeliveryDays}
                          />
                        </Col>
                        <Col>
                          <DayCheckBox
                            day={DeliveryDays.SUNDAY}
                            label="Sun"
                            data={deliveryDays}
                            onChange={changeDeliveryDays}
                          />
                        </Col>
                        <Col>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label>Pincode</Label>
                      <div className="d-flex ">
                        <Input
                          name="pincodes"
                          type="textarea"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.pincodes}
                          placeholder="Enter comma separated pincodes"
                        ></Input>
                      </div>
                      {validation.touched.pincodes && validation.errors.pincodes ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.pincodes}</FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Zone.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default Zone;