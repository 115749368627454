import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

export const getAllOrdersAPI = (page, startDate, endDate) => post(url.ALL_ORDERS + "/" + page, {startDate, endDate});
export const getUpcomingOrders = (page, startDate, endDate) => post(url.UPCOMING_ORDERS + "/" + page, {startDate, endDate});
export const getOngoingOrders = (page, startDate, endDate) => post(url.ONGOING_ORDERS + "/" + page, {startDate, endDate}); 
export const getDueReturns = (page,startDate, endDate) => post(url.DUE_RETURNS + "/" + page,{startDate, endDate});
export const getPreBookings = (page,startDate, endDate) => post(url.PRE_BOOKINGS + "/" + page, {startDate, endDate});
export const changeOrderStatus = (orderIds, status) => put(url.CHANGE_ORDER_STATUS_API, { orderIds, status });
export const cancelOrder = (orderId, reason) => post(url.CANCEL_ORDER_API, { orderId, reason });
export const getCancelledOrdersAPI = (page, startDate, endDate) => post(url.CANCELLED_ORDERS + "/" + page, {startDate, endDate});