import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import {
  changeOrderStatus,
  getPickUpPoints,
  getUpcomingOrders as onGetUpcoming,
  getPaymentHistory
} from "store/actions";


//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import OffCanvasComponent from "pages/Customers/OffCanvasComponent";
import { mkConfig, generateCsv, download } from "export-to-csv";

function PaymentHistory() {

  //meta title
  document.title = "Payment History";
  const dispatch = useDispatch();

  const selectPaymentState = (state) => state.Payment;
  const PaymentProperties = createSelector(
    selectPaymentState,
    (Payment) => ({
      paymenstHistory: Payment.payments,
      totalRecords: Payment.totalRecords
    })
  );
  const { paymenstHistory, totalRecords } = useSelector(PaymentProperties);

  const [isLoading, setLoading] = useState(true);
  const [pageNumber, setPage] = useState(1);

  const [startDate, setStartDate] = useState(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
  );
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  });

  const [isCanvasOpen, setIsCanvasOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const toggleCanvas = () => {
      setIsCanvasOpen(!isCanvasOpen);
  };

  const onOrderClick= (orderDetails) => {
      toggleCanvas();
      setSelectedOrder(orderDetails);
  }

  const [orderChecked, setOrderChecked] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [orderStatus, setOrderStatus] = useState("pending");

   useEffect(() => {
    dispatch(getPaymentHistory(pageNumber, startDate, endDate));
  }, [dispatch, pageNumber, startDate, endDate]);

  const columns = useMemo(
    () => [
      {
        Header: 'Order IDs',
        accessor: "orderId",
        width: "150px",
        style: {
            textAlign: "center",
            width: "10%",
            background: "#0000",
        },
        filterable: true,
        Cell: ({ row }) => {
            return <>{row?.original?.orders?.map(order=> order.orderId).join(",   ") ?? " "} </>;
        },
      },
      {
        Header: 'Payment ID',
        accessor: "razorpayPaymentId",
        width: "150px",
        style: {
            textAlign: "center",
            width: "10%",
            background: "#0000",
        },
        filterable: true,
        Cell: ({ row }) => {
            return <>{row?.original?.razorpayPaymentId ?? " "} </>;
        },
      },
      {
        Header: 'Razorpay Order ID',
        accessor: "razorpayOrderId",
        width: "150px",
        style: {
            textAlign: "center",
            width: "10%",
            background: "#0000",
        },
        filterable: true,
        Cell: ({ row }) => {
            return <>{row?.original?.razorpayOrderId ?? " "} </>;
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            ₹ {cellProps?.row?.original?.amount}
          </>;
        }
      },
      {
        Header: 'Customer Name',
        accessor: 'name',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.customer?.fname + " " + cellProps?.row?.original?.customer?.lname}
          </>;
        }
      },
      {
        Header: 'Email ID',
        accessor: 'email',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.customer?.email}
          </>;
        }
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.customer?.mobile}
          </>;
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          // show status in rounded badge for success and danger
          return <>
            {
              cellProps?.row?.original?.status === "Captured" ?
                <span className="badge bg-success p-2">Captured</span> :
                <span className="badge bg-danger p-2">Failed</span>
            }
          </>;
        }
      },
    ],
    []
  );
  
  return (
    <React.Fragment>


      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Payment History" />
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>

                      <TableContainer
                        title="Payment History"
                        columns={columns}
                        data={paymenstHistory || []}
                        isGlobalFilter={true}
                        isStartDate={true}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        isEndDate={true}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        handleOrderClicks={() => { }}
                        customPageSize={10}
                        isPagination={false}
                        filterable={false}
                        // csvExport={true}
                        // exportToCSVButtonClicked={exportToCSVButtonClicked}
                        iscustomPageSizeOptions={true}
                        setAllChecked={setAllChecked}
                        allChecked={allChecked}
                        setOrderChecked={setOrderChecked}
                        orderChecked={orderChecked}
                        isCheckable={true}
                        setOrderStatus={setOrderStatus}
                        orderStatus={orderStatus}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        isCustomPagination={true}
                        setPage={setPage}
                        pageNumber={pageNumber}
                        totals={totalRecords}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }          
        </div>
      </div>
      <ToastContainer />
      <OffCanvasComponent
        isCanvasOpen={isCanvasOpen}
        toggleCanvas={toggleCanvas}
        selectedOrder={selectedOrder} 
      />
    </React.Fragment>
  );
}
PaymentHistory.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default PaymentHistory;

