import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import {
    getTags as onGetTags,
    updateTag as onUpdateTag,
    addNewTag as onAddNewTag,
    deleteTag as onDeleteTag,
    getFAQs as onGetFAQs,
    addNewFAQ as onAddNewFAQ,
    updateFAQ as onUpdateFAQ,
    deleteFAQ as onDeleteFAQ,
} from "store/actions";


//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

function FAQs() {

    //meta title
    document.title = "Master >> FAQs";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [faq, setFaq] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            question: faq ? faq.question : '',
            answer: faq ? faq.answer : '',
        },

        validationSchema: Yup.object({
            question: Yup.string().required('Question is required'),
            answer: Yup.string().required('Answer is required'),
        }),

        onSubmit: (values) => {
            if (isEdit) {
                const updateFAQ = {
                    _id: faq._id,
                    question: values["question"],
                    answer: values["answer"],
                };
                dispatch(onUpdateFAQ(updateFAQ));
                validation.resetForm();
            } else {
                const newFAQ = {
                    question: values["question"],
                    answer: values["answer"],
                };
                dispatch(onAddNewFAQ(newFAQ));
                validation.resetForm();
            }
            toggle();
        },
    });


    const dispatch = useDispatch();

    const selectFAQState = (state) => state.Faq;
    const FAQProperties = createSelector(
        selectFAQState,
        (FAQ) => ({
            faqs: FAQ.faqs
        })
    );

    const { faqs } = useSelector(FAQProperties);
    useEffect(() => {
        dispatch(onGetFAQs());
    }, [dispatch]);


    const toggle = () => {
        if (modal) {
            setModal(false);
            setFaq(null);
        } else {
            setModal(true);
        }
    };

    const handleFAQClick = arg => {
        const faq = arg;
        setFaq({
            _id: faq._id,
            question: faq.question,
            answer: faq.answer,
        });
        setIsEdit(true);
        toggle();   
    };

    const onClickDeleteFAQ = (faq) => {
        setFaq(faq);
        setDeleteModal(true);
    };

    const handleFAQDelete = () => {
        if (faq && faq._id) {
            dispatch(onDeleteFAQ(faq._id));
            setDeleteModal(false);
        }
    };

    const handleAddFAQClick = () => {
        setIsEdit(false);
        setFaq(null);
        toggle();
    };


    const columns = useMemo(
        () => [
            {
                Header: 'S.No',
                accessor: 'sno',
                width: '50px',
                style: {
                    textAlign: "center",
                    width: "10%",
                },
                Cell: (cellProps) => {
                    return <div>{cellProps.row.original.id}</div>;
                }
            },
            {
                Header: 'FAQs',
                accessor: 'faq',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        <div className="faq-accordion">
                            <div className="faq-accordion-header">
                                <h4 className="faq-accordion-title">{cellProps.row.original.question} ?</h4>
                            </div>
                            <div className="faq-accordion-body">
                                <p>{cellProps.row.original.answer}</p>
                            </div>
                        </div>
                    </>;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const faq = cellProps.row.original;
                                    handleFAQClick(faq);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const faqData = cellProps.row.original;
                                    onClickDeleteFAQ(faqData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleFAQDelete}
                onCloseClick={() => setDeleteModal(false)}
                title="FAQ"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Masters" breadcrumbItem="FAQs" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>

                                            <TableContainer
                                                title={"FAQ"}
                                                columns={columns}
                                                data={faqs || []}
                                                isGlobalFilter={true}
                                                isAddOptions={true}
                                                handleOrderClicks={handleAddFAQClick}
                                                customPageSize={10}
                                                isPagination={true}
                                                filterable={false}
                                                iscustomPageSizeOptions={true}
                                                tableClass="align-middle table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-end mb-2"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit FAQ" : "Add FAQ"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">



                                        <div className="mb-3">
                                            <Label>Question</Label>
                                            <Input
                                                name="question"
                                                type="text"
                                                placeholder="Enter Question"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.question || ''}
                                                invalid={
                                                    validation.touched.question && validation.errors.question ? true : false
                                                }
                                            />
                                            {validation.touched.question && validation.errors.question ? (
                                                <FormFeedback type="invalid">{validation.errors.question}</FormFeedback>
                                            ) : null}
                                        </div>



                                        <div className="mb-3">
                                            <Label>Answer</Label>
                                            <Input
                                                name="answer"
                                                type="text"
                                                placeholder="Enter Answer"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.answer || ''}
                                                invalid={
                                                    validation.touched.answer && validation.errors.answer ? true : false
                                                }
                                            />
                                            {validation.touched.answer && validation.errors.answer ? (
                                                <FormFeedback type="invalid">{validation.errors.answer}</FormFeedback>
                                            ) : null}
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}

FAQs.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};


export default FAQs;