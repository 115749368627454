import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty, set } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getMembers, getCustomers as onGetCustomers } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import moment from "moment";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";

import {
    getZones,
    getCities,
    deleteCustomer,
    getCustomerEnquiries
} from "store/actions";
import DeleteModal from "components/Common/DeleteModal";
import { post, put } from "helpers/api_helper";
import { formatDate } from "helpers/date_helper";
import AddNoteModal from "./addNoteModal";

function IncompleteRegistration() {

    //meta title
    document.title = "Incomplete Registration";

    const dispatch = useDispatch();
    const [pageNumber, setPage] = useState(1);

    const selectMemberState = (state) => state.Member;
    const MemberProperties = createSelector(
      selectMemberState,
      (Member) => ({
        members: Member.members,
        totalMembers: Member.totalMembers,
      })
    );
    const { members, totalMembers } = useSelector(MemberProperties);

   
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        console.log("pageNumber", pageNumber);
        dispatch(getMembers(pageNumber, "incompleteRegistration"));
    }, [dispatch,pageNumber]);
    

    const [modal, setModal] = useState(false);
    const [note, setNote] = useState("");
    const [selectedMember, setSelectedMember] = useState("");
    const handleMemberClick = (customerData) => {
        setModal(true);
        setSelectedMember(customerData);
        setNote(customerData?.note || "");
    }

    const addNote = async (id, note) => {
        try {
            const response = await post(`members/add-note`, { Id: id, Note: note });
            toast.success("Note added successfully");
            dispatch(getMembers(pageNumber, "incompleteRegistration"));
            setModal(false);
            setNote("");
        } catch (error) {
            console.log("error", error);
            toast.error(`${error?.response?.data?.message || "Error in adding note"}`);
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Mobile',
                accessor: 'mobile',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.mobile}
                    </>;
                }
            },
            {
                Header: 'Registered On',
                accessor: 'registeredOn',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {formatDate(cellProps?.row?.original?.createdAt)}
                    </>;
                }
            },
            {
                Header: 'Mobile Verified',
                accessor: 'mobileVerified',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.isMobileVerified ? 
                        <span className="badge bg-success p-2">Yes</span> :
                        <span className="badge bg-danger p-2">No</span>}
                    </>;
                }
            },
            {
                Header: 'Note',
                accessor: 'note',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    if (cellProps?.row?.original?.note) {
                        return cellProps?.row?.original?.note?.split("\n").map((note, index) => {
                            return <span key={index}>{note} <br /></span>
                        });
                    } else {
                        return <span>No Note</span>;
                    }
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                width: '10%',
                disableFilters: true,
                Cell: (cellProps) => {
                  return (
                    <div>
                      <Button
                        color="primary"
                        onClick={
                          () => handleMemberClick( cellProps?.row?.original)
                        }
                      >
                        Add Note
                      </Button>
                    </div>
                  );
                }
              },
        ],
        []
    );

    return (
        <React.Fragment>
            <AddNoteModal 
                show={modal}
                title="Add Note"
                onCloseClick={() => setModal(false)}
                onAddClick={() => addNote(selectedMember?._id, note)}
                setNote={setNote}
                selectedMember={selectedMember}
                note={note}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Incomplete Registration" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>

                                            <TableContainer
                                                columns={columns}
                                                data={members || []}
                                                isGlobalFilter={true}
                                                handleOrderClicks={() => { }}
                                                customPageSize={10}
                                                // isPagination={true}
                                                filterable={false}
                                                iscustomPageSizeOptions={true}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-end mb-2"
                                                isCustomPagination={true}
                                                setPage={setPage}
                                                pageNumber={pageNumber}
                                                totals={totalMembers}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
IncompleteRegistration.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};


export default IncompleteRegistration;