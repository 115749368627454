import { call, put, takeEvery } from "redux-saga/effects";

// City Redux States
import {
  GET_FAQS,
  ADD_NEW_FAQ,
  UPDATE_FAQ,
  DELETE_FAQ
} from "./actionTypes";
import {
  getFAQsSuccess,
  getFAQsFail,
  addFAQSuccess,
  addFAQFail,
  updateFAQFail,
  updateFAQSuccess,
  deleteFAQFail,
  deleteFAQSuccess
} from "./actions";

import {
  getFAQs,
  updateFAQ,
  deleteFAQ,
  addNewFAQ
} from "../../helpers/faqs_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


// FAQs
function* fetchFAQs() {
  try {
    const response = yield call(getFAQs);
    yield put(getFAQsSuccess(response));
  } catch (error) {
    yield put(getFAQsFail(error));
  }
}

function* onUpdateFAQ({ payload: faq }) {
  try {
    const response = yield call(updateFAQ, faq);
    yield put(updateFAQSuccess(response));
    toast.success("FAQ Update Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateFAQFail(error));
    toast.error("FAQ Update Failed", { autoClose: 2000 });
  }
}

function* onDeleteFAQ({ payload: faq }) {
  try {
    const response = yield call(deleteFAQ, faq);
    yield put(deleteFAQSuccess(response));
    toast.success("FAQ Delete Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteFAQFail(error));
    toast.error("FAQ Delete Failed", { autoClose: 2000 });
  }
}

function* onAddNewFAQ({ payload: faq }) {
  try {
    const response = yield call(addNewFAQ, faq);
    yield put(addFAQSuccess(response));
    toast.success("FAQ Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addFAQFail(error));
    toast.error("FAQ Added Failed", { autoClose: 2000 });
  }
}


function* FAQSaga() {
  // FAQs
  yield takeEvery(GET_FAQS, fetchFAQs);
  yield takeEvery(ADD_NEW_FAQ, onAddNewFAQ);
  yield takeEvery(UPDATE_FAQ, onUpdateFAQ);
  yield takeEvery(DELETE_FAQ, onDeleteFAQ);
}

export default FAQSaga;
