import {
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  GET_MEMBER_IDS_SUCCESS,
  GET_MEMBER_IDS_FAIL,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_DETAILS_FAIL,
  ON_DELETE_MEMBER_FAIL,
  ON_DELETE_MEMBER_SUCCESS,
  GET_MEMBER_TOKENS_SUCCESS,
  GET_MEMBER_TOKENS_FAIL,
  GET_GH_SUCCESS,
  GET_GH_FAIL,
  GET_PIN_ENQUIRIES,
  GET_PIN_ENQUIRIES_SUCCESS,
  GET_PIN_ENQUIRIES_FAIL
} from "./actionTypes";

const INIT_STATE = {
  members: [],
  totalMembers: 0,
  memberIds: [],
  totalMemberIds: 0,
  member: {},
  tokens: [],
  gh: [],
  loading: true,
  error: null,
};

const Member = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload?.members || [],
        totalMembers: action.payload?.totalMembers,
        loading: false
      };
    
    case GET_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case GET_MEMBER_IDS_SUCCESS:
      return {
        ...state,
        memberIds: action.payload?.memberIds || [],
        totalMemberIds: action.payload?.totalMemberIds,
        loading: false
      };

    case GET_MEMBER_IDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case GET_MEMBER_DETAILS_SUCCESS:
      return {
        ...state,
        member: action.payload,
        loading: false
      };

    case GET_MEMBER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case ON_DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.filter(member => member._id !== action.payload._id),
        loading: false
      };

    case ON_DELETE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case GET_MEMBER_TOKENS_SUCCESS:
      return {
        ...state,
        tokens: action.payload,
        loading: false
      };
    
    case GET_MEMBER_TOKENS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    
    case GET_GH_SUCCESS:
      return {
        ...state,
        gh: action.payload,
        loading: false
      };
    
    case GET_GH_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
    };

    case GET_PIN_ENQUIRIES:
      return {
        ...state,
        loading: true
      };
    
    case GET_PIN_ENQUIRIES_SUCCESS:
      return {
        ...state,
        members: action.payload?.members || [],
        totalMembers: action.payload?.totalMembers,
        loading: false
      };

    case GET_PIN_ENQUIRIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
      
    default:
      return {
        ...state
      };
  }
};

export default Member;
