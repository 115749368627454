import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { isEmpty, set } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';


import {
  getTags as onGetTags,
} from "store/actions";
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { get, post, put } from "helpers/api_helper";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TableContainer from "components/Common/TableContainer";

function ViewContainerMembers() {

  //meta title
  document.title = "Assigned SH";
  const adminTokenID = process.env.REACT_APP_ADMIN_TOKEN_ID;
  const adminId = process.env.REACT_APP_ADMIN_ID;
  const [isLoading, setLoading] = useState(false);

  const location = useLocation(); //from react-router-dom
  const searchParams = new URLSearchParams(location.search);
  const containerId = searchParams.get("containerId");
  const containerName = searchParams.get("containerName");

  const [recipientDate, setRecipientDate] = useState("");
  const [senderDate, setSenderDate] = useState("");
  const dispatch = useDispatch();

  // const [recipient, setRecipient] = useState({});
  // const [senders, setSenders] = useState([]);
  const [data, setData] = useState({
    recipient: {},
    senders: [],
  });

  const [dataSenders, setDataSenders] = useState([]);
  const [dataRecipient, setDataRecipient] = useState([]);

  const [assignToAdmin, setAssignToAdmin] = useState(false);
  const [showContainerMembers, setShowContainerMembers] = useState(false);

  const fetchSenders = async(date) => {
    setLoading(true);
    try {
      const response = await get("/members/sh/?date=" + date + "&&type=sender" + "&&tag=" + senderTag);
      console.log("response", response);
      setDataSenders(response.data);
    }
    catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  }

  const fetchRecipient = async(date) => {
    setLoading(true);
    try {
      const response = await get("/members/sh/?date=" + date + "&&type=recipient" + "&&tag=" + receiverTag);
      console.log("response", response);
      setDataRecipient(response.data);
    }
    catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  }

  const [senderTag, setSenderTag] = useState("All")
  const [receiverTag, setReceiverTag] = useState("All")

  const selectTagState = (state) => state.Tag;
  const TagProperties = createSelector(
      selectTagState,
      (Tag) => ({
          tags: Tag.tags
      })
  );

  const { tags } = useSelector(TagProperties);

  useEffect(() => {
    dispatch(onGetTags());
}, [dispatch]);

  // useEffect(() => {
  //   fetchSenders();
  // }, [senderTag]);

  // useEffect(() => {
  //   fetchRecipient();
  // }, [receiverTag]);
  

  const sendHelp = async (payload) => {
    try {
      const finalPayload = {
        ...payload,
        senders: data.senders.map((sender) => {
          return {
            _id: sender._id,
            memberId: sender?.memberId,
          }
        }),
      }
      console.log("finalPayload", finalPayload);
      const response = await post("/members/assign-send-help", finalPayload);
      const filteredData = [...dataSenders].filter((sender) => !data.senders.find((dataSender) => dataSender._id === sender._id));
      setDataSenders(filteredData);
      
      if(!payload.sendToAdmin)
        setDataRecipient([...dataRecipient].filter((dataRecipient) => dataRecipient._id !== data?.recipient?._id));

      setData({
        recipient: {},
        senders: [],
      });
      toast.success(`${response?.message || "SH Assigned Successfully"}`, { autoClose: 2000 });
    }
    catch (error) {
      console.log("error", error);
      toast.error("SH Assign Failed");
    }
    setLoading(false);
  }

  const sendHelpToMember = () => {
    if(isEmpty(data.recipient)){
      toast.error("Please select a recipient");
      return;
    }
    if(data.senders.length === 0){
      toast.error("Please select at least one sender");
      return;
    }
    const payload = {
      recipient: {
        memberId: data.recipient?.memberId,
        _id: data.recipient?._id,
      },
      sendToAdmin: false,
    }
      
    sendHelp(payload);
  }

  const sendHelpToAdmin = () => {
    const payload = {
      recipient: {
        memberId: adminId,
        _id: adminTokenID,
      },
      sendToAdmin: true,
    }
    if(data.senders.length === 0)
      toast.error("Please select at least one sender");
    else
      sendHelp(payload);
  }

  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [year, month, day].join('-');
  };

  const [isEdit, setIsEdit] = useState(false)
  const [date, setDate] = useState(formatDate(new Date()))

  const handleDateChange = (e) => { 
    setDate(e.target.value)
  }

  const [containerMembers, setContainerMembers] = useState([]);

  const fetchContainerMembers = async (containerId) => {
    try {
      const res = await get(`/members/fetch-container-members/${containerId}`);
      console.log(res)
      setContainerMembers(res);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(containerId) {
      fetchContainerMembers(containerId);
    }
  }, [containerId])
      
  const columns = useMemo(
    () => [
        {
            Header: 'Sender Name',
            accessor: 'senderName',
            width: '150px',
            style: {
                textAlign: "center",
                width: "10%",
                background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
                return <>
                    {cellProps?.row?.original?.memberId?.fname + " " + cellProps?.row?.original?.memberId?.lname  + " ( " + cellProps?.row?.original?.tokenId + " )"}
                </>;
            }
        },
        {
            Header: 'Recipient Name',
            accessor: 'recipientName',
            width: '150px',
            style: {
                textAlign: "center",
                width: "10%",
                background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
                return <>
                    {cellProps?.row?.original?.sendHelp?.recipientUserId?.fname && cellProps?.row?.original?.sendHelp?.recipientUserId?.lname ? cellProps?.row?.original?.sendHelp?.recipientUserId?.fname + " " + cellProps?.row?.original?.sendHelp?.recipientUserId?.lname + " ( " + cellProps?.row?.original?.sendHelp?.recipient?.tokenId + " )" : "Not Assigned"}
                </>;
            }
        },
        {
            Header: 'Mobile Number',
            accessor: 'mobile',
            width: '150px',
            style: {
                textAlign: "center",
                width: "10%",
                background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
                return <>
                    {cellProps?.row?.original?.memberId?.mobile}
                </>;
            }
        },
        {
            Header: 'Status',
            accessor: 'status',
            width: '150px',
            style: {
                textAlign: "center",
                width: "10%",
                background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
                const date = formatDate(cellProps?.row?.original?.createdAt)
                const isHighlighted = date === formatDate(new Date());
                return <>
                {
                  cellProps?.row?.original?.sendHelp?.status === undefined ? <span className="badge bg-warning p-2">NOT ASSIGNED</span> :
                  <>
                    {cellProps?.row?.original?.sendHelp?.status === "COMPLETED" ? 
                        <span className="badge bg-success p-2">{cellProps?.row?.original?.sendHelp?.status}</span> :
                        <span className={cellProps?.row?.original?.sendHelp?.status === "PAYMENT_PENDING" ? "badge bg-danger p-2" : "badge bg-danger p-2"}>{cellProps?.row?.original?.sendHelp?.status}</span>}
                  </>
                }
                </>;
            }
        },
        // {
        //     Header: 'Published',
        //     accessor: 'published',
        //     width: '150px',
        //     style: {
        //         textAlign: "center",
        //         width: "10%",
        //         background: "#0000",
        //     },
        //     filterable: true,
        //     Cell: (cellProps) => {
        //         return <>
        //             {cellProps?.row?.original?.isPublished ? 
        //                 <span className="badge bg-success p-2">Yes</span> : 
        //                 <span className="badge bg-danger p-2">No</span>
        //             }
        //         </>;
        //     }
        // },
        // {
        //     Header: 'Action',
        //     accessor: 'action',
        //     disableFilters: true,
        //     Cell: (cellProps) => {
        //         return (
        //             <div className="d-flex gap-3">
        //                 <Link
        //                   to="#"
        //                   className="text-success"
        //                   onClick={() => {
        //                     setEditNoteModal(true)
        //                     setContainerName(cellProps.row.original.containerName)
        //                     setNote(cellProps.row.original.note)
        //                   }}
        //                 >
        //                   <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
        //                   <UncontrolledTooltip placement="top" target="edittooltip">
        //                     Edit Note
        //                   </UncontrolledTooltip>
        //                 </Link>
        //                 <Button
        //                     type="button"
        //                     color="primary"
        //                     className="btn btn-primary btn-sm"
        //                     onClick={() => {
        //                       setPublishModal(true)
        //                       setContainerName(cellProps.row.original.containerName)
        //                     }}
        //                     // onClick={() => handlePublishClick(cellProps.row.original)}
        //                 >
        //                     Publish
        //                 </Button>
        //                 <Button 
        //                   type="button"
        //                   color="primary"
        //                   className="btn btn-primary btn-sm"
        //                   onClick={() => {
        //                     setContainerId(cellProps.row.original._id)
        //                   }}
        //                 >
        //                   View
        //                 </Button>
        //                 {/* <Link
        //                     to="#"
        //                     className="text-danger"
        //                     onClick={() => {
        //                         const day = cellProps.row.original;
        //                         onClickDelete(day);
        //                     }}
        //                 >
        //                     <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
        //                     <UncontrolledTooltip placement="top" target="deletetooltip">
        //                         Delete
        //                     </UncontrolledTooltip>
        //                 </Link> */}
        //             </div>
        //         );
        //     }
        // },
    ],
    []
);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem={`Assigned SH / Container Members / ${containerName}`} />
          <div className="d-flex justify-content-end gap-2 mb-2">
            <Link
              to={`/assignmembers?createNewContainer=true&&containerId=${containerId}&&containerName=${containerName}`}
              className="text-success"
            >
            <button className="btn btn-primary">Assign SH</button>
            </Link>
            <Link
              to={`/assignmembers`}
              className="text-success"
            >
            <button className="btn btn-danger">Back</button>
            </Link>
          </div>
          {
            isLoading ? <Spinners/>
              :
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <TableContainer
                            title={"Day"}
                            columns={columns}
                            data={containerMembers || []} 
                            isGlobalFilter={true}
                            // isAddOptions={true}
                            customPageSize={11}
                            isPagination={true}
                            filterable={false}
                            iscustomPageSizeOptions={true}
                            tableClass="align-middle table-nowrap table-check"
                            theadClass="table-light"
                            pagination="pagination pagination-rounded justify-content-end mb-2"
                            // createContainer={true}
                            handleCreateContainerClick={() => setCreateModal(true)}
                            handlePublishClick={() => setPublishModal(true)}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
          }
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

ViewContainerMembers.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default ViewContainerMembers;