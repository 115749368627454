// FAQ action types
export const GET_FAQS = "GET_FAQS"
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS"
export const GET_FAQS_FAIL = "GET_FAQS_FAIL"

export const ADD_NEW_FAQ = "ADD_NEW_FAQ"
export const ADD_NEW_FAQ_SUCCESS = "ADD_NEW_FAQ_SUCCESS"
export const ADD_NEW_FAQ_FAIL = "ADD_NEW_FAQ_FAIL"

export const UPDATE_FAQ = "UPDATE_FAQ"
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS"
export const UPDATE_FAQ_FAIL = "UPDATE_FAQ_FAIL"

export const DELETE_FAQ = "DELETE_FAQ"
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS"
export const DELETE_FAQ_FAIL = "DELETE_FAQ_FAIL"