import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getPinEnquiries as getPinEnquiries } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Button,
    Col,
    Row,
    Card,
    CardBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";

import { post } from "helpers/api_helper";
import { formatDate } from "helpers/date_helper";
import PinSendModal from "./PinSendModal";

function PinEnquiry() {

    //meta title
    document.title = "PIN Enquiry";

    const dispatch = useDispatch();
    const [pageNumber, setPage] = useState(1);

    const selectMemberState = (state) => state.Member;
    const MemberProperties = createSelector(
      selectMemberState,
      (Member) => ({
        members: Member.members,
        totalMembers: Member.totalMembers,
      })
    );
    const { members, totalMembers } = useSelector(MemberProperties);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getPinEnquiries(pageNumber));
    }, [dispatch,pageNumber]);
    

    const [pinSendModal, setPinSendModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState("");
    const handleMemberClick = (customerData) => {
        setPinSendModal(true);
        setSelectedMember(customerData);
    }

    const sendPins = async () => {
        try {
            const response = await post(`members/sendPin`, { 
                id: selectedMember?._id, 
                memberId: selectedMember?.requestedBy?._id,
                qty: selectedMember?.qty,  
            });
            console.log("response", response);
            toast.success("Pins sent successfully");
            setPinSendModal(false);
            dispatch(getPinEnquiries(pageNumber));
        } catch (error) {
            console.log("error", error);
            toast.error("Failed to send pins");
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'fname',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.requestedBy?.fname + " " + cellProps?.row?.original?.requestedBy?.lname}
                    </>;
                }
            },
            {
                Header: 'Mobile',
                accessor: 'mobile',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.requestedBy?.mobile}
                    </>;
                }
            },
            {
                Header: 'Requested Pins',
                accessor: 'requestedPins',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.qty}
                    </>;
                }
            },
            {
                Header: 'Requested On',
                accessor: 'requestedOn',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {formatDate(cellProps?.row?.original?.createdAt)}
                    </>;
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.status === "SENT" ? 
                        <span className="badge bg-success p-2">{cellProps?.row?.original?.status}</span> :
                        <span className="badge bg-danger p-2">{cellProps?.row?.original?.status}</span>}
                    </>;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                width: '10%',
                disableFilters: true,
                Cell: (cellProps) => {
                  return (
                    <div>
                      <Button
                        color="primary"
                        onClick={
                          () => handleMemberClick(cellProps?.row?.original)
                        }
                      >
                        View
                      </Button>
                    </div>
                  );
                }
              },
        ],
        []
    );
    const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
    return (
        <React.Fragment>
            <PinSendModal
                show={pinSendModal}
                onCloseClick={() => setPinSendModal(false)}
                qty={selectedMember?.qty}
                name={selectedMember?.requestedBy?.fname + " " + selectedMember?.requestedBy?.lname}
                mobile={selectedMember?.requestedBy?.mobile}
                src={
                    selectedMember?.screenShot?.includes("s3.amazonaws.com") ?
                    selectedMember?.screenShot : imageBaseUrl + selectedMember?.screenShot?.replace("uploads", "upload")
                }
                sent={selectedMember?.status}
                onSendClick={sendPins}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="PIN Enquiry" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={members || []}
                                            isGlobalFilter={true}
                                            handleOrderClicks={() => { }}
                                            customPageSize={10}
                                            // isPagination={true}
                                            filterable={false}
                                            iscustomPageSizeOptions={true}
                                            tableClass="align-middle table-nowrap table-check"
                                            theadClass="table-light"
                                            pagination="pagination pagination-rounded justify-content-end mb-2"
                                            isCustomPagination={true}
                                            setPage={setPage}
                                            pageNumber={pageNumber}
                                            totals={totalMembers}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
PinEnquiry.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};


export default PinEnquiry;