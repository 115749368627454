import PropTypes from 'prop-types'
import React from "react"
import { Modal, ModalBody } from "reactstrap"

const AddNoteModal = ({ show, title,  onCloseClick, onAddClick, selectedMember, setNote, note }) => {
return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
        <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
                <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
                <div className="avatar-sm mb-2 mx-auto">
                    <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                        <i className="mdi mdi-video-account" style={{fontSize: "35px"}}></i>
                        </div>
                </div>
                    <h1 className="text-muted font-size-16 mb-4">{title}</h1>
                    <h2 className="text-muted font-size-16 mb-4">Mobile Number: {selectedMember?.mobile}</h2>

                    <textarea
                        className="form-control"
                        placeholder="Add Note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        style={{ minHeight: "150px" }} 
                    ></textarea>
                <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                    <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Close</button>
                    <button type="button" className="btn btn-success" onClick={onAddClick}>Add</button>
                </div>
            </ModalBody>
        </div>
    </Modal>
)
}

AddNoteModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  title: PropTypes.string,
  onAddClick: PropTypes.func,
  selectedMember: PropTypes.any,
  setNote: PropTypes.func,
  note: PropTypes.any,
}

export default AddNoteModal
