import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';


import {
  getTags as onGetTags,
} from "store/actions";
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { get, post, put } from "helpers/api_helper";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

function AssignMembers({ sendersData, recipientData, containerId, setCreateNewContainer, setContainerId, containerName, setCreateNewContainerFromUrlBool }) {

  //meta title
  document.title = "Assign SH";
  const adminTokenID = process.env.REACT_APP_ADMIN_TOKEN_ID;
  const adminId = process.env.REACT_APP_ADMIN_ID;
  const [isLoading, setLoading] = useState(false);

  const [recipientDate, setRecipientDate] = useState("");
  const [senderDate, setSenderDate] = useState("");
  const dispatch = useDispatch();

  const containerIdFromUrl = new URLSearchParams(window.location.search).get("containerId");
  const containerNameFromUrl = new URLSearchParams(window.location.search).get("containerName");

  // const [recipient, setRecipient] = useState({});
  // const [senders, setSenders] = useState([]);
  const [data, setData] = useState({
    recipient: {},
    senders: [],
  });

  const [dataSenders, setDataSenders] = useState(sendersData);
  const [dataRecipient, setDataRecipient] = useState(recipientData);

  console.log(sendersData, recipientData);
  console.log(dataSenders, dataRecipient);

  useEffect(() => {
    setDataSenders(sendersData);
  }, [sendersData]);

  useEffect(() => {
    setDataRecipient(recipientData);
  }, [recipientData]);

  const [assignToAdmin, setAssignToAdmin] = useState(false);

  const fetchSenders = async() => {
    setLoading(true);
    try {
      const response = await get("/members/sh/?date=" + senderDate + "&&type=sender" + "&&tag=" + senderTag + "&&containerId=" + containerIdFromUrl);
      console.log("response", response);
      setDataSenders(response.data);
    }
    catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  }

  const fetchRecipient = async() => {
    setLoading(true);
    try {
      const response = await get("/members/sh/?date=" + recipientDate + "&&type=recipient" + "&&tag=" + receiverTag + "&&containerId=" + containerIdFromUrl);
      console.log("response", response);
      setDataRecipient(response.data);
    }
    catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  }

  const [senderTag, setSenderTag] = useState("All")
  const [receiverTag, setReceiverTag] = useState("All")

  const selectTagState = (state) => state.Tag;
  const TagProperties = createSelector(
      selectTagState,
      (Tag) => ({
          tags: Tag.tags
      })
  );

  const { tags } = useSelector(TagProperties);

  useEffect(() => {
    dispatch(onGetTags());
}, [dispatch]);

  useEffect(() => {
    fetchSenders();
  }, [senderTag, containerIdFromUrl, containerNameFromUrl]);

  useEffect(() => {
    fetchRecipient();
  }, [receiverTag, containerIdFromUrl, containerNameFromUrl]);
  

  const sendHelp = async (payload) => {
    try {
      const finalPayload = {
        containerId: containerId,
        ...payload,
        senders: data.senders.map((sender) => {
          return {
            _id: sender._id,
            memberId: sender?.memberId,
          }
        }),
      }
      console.log("finalPayload", finalPayload);
      const response = await post("/members/assign-send-help", finalPayload);
      const filteredData = [...dataSenders].filter((sender) => !data.senders.find((dataSender) => dataSender._id === sender._id));
      setDataSenders(filteredData);
      
      if(!payload.sendToAdmin)
        setDataRecipient([...dataRecipient].filter((dataRecipient) => dataRecipient._id !== data?.recipient?._id));

      setData({
        recipient: {},
        senders: [],
      });
      toast.success(`${response?.message || "SH Assigned Successfully"}`, { autoClose: 2000 });
    }
    catch (error) {
      console.log("error", error);
      toast.error("SH Assign Failed");
    }
    setLoading(false);
  }

  const sendHelpToMember = () => {
    if(isEmpty(data.recipient)){
      toast.error("Please select a recipient");
      return;
    }
    if(data.senders.length === 0){
      toast.error("Please select at least one sender");
      return;
    }
    const payload = {
      recipient: {
        memberId: data.recipient?.memberId,
        _id: data.recipient?._id,
      },
      sendToAdmin: false,
    }
      
    sendHelp(payload);
  }

  const sendHelpToAdmin = () => {
    const payload = {
      recipient: {
        memberId: adminId,
        _id: adminTokenID,
      },
      sendToAdmin: true,
    }
    if(data.senders.length === 0)
      toast.error("Please select at least one sender");
    else
      sendHelp(payload);
  }


const [membersCount, setMembersCount] = useState(0);

  const fetchContainerMembers = async (containerId) => {
    try {
      const res = await get(`/members/fetch-container-members/${containerId}`);
      setMembersCount(res.length);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(containerId || containerIdFromUrl || containerNameFromUrl) {
      fetchContainerMembers(containerId || containerIdFromUrl);
    }
  }, [containerId, containerIdFromUrl, containerNameFromUrl])

  return (
    <React.Fragment>
      <div className="">
        <div className="container-fluid">
        <div className="d-flex justify-content-end gap-2 mb-2">
          <Link
            to={`/container-members?containerId=${containerId}&containerName=${containerName}`}
            className="text-success"
          >
          <button className="btn btn-primary">View Assignments ({membersCount})</button>
          </Link>
          <button className="btn btn-danger" onClick={() => {setCreateNewContainer(false); setCreateNewContainerFromUrlBool(false)}}>Back</button>
        </div>
          {
            isLoading ? <Spinners/>
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody className=" d-flex flex-column justify-content-center">
                      <div className="d-flex w-full justify-content-around">
                        <div className="w-[300px] w-full d-flex flex-column gap-2">
                          <div className=" d-flex w-full justify-content-between align-item-center gap-2">
                            {/* <div className=" d-flex w-full justify-content-between align-item-center gap-2">
                              <input
                                type="date"
                                id="startDate"
                                className="form-control w-full"
                                value={recipientDate}
                                onChange={(event) => setRecipientDate(event.target.value)}
                              />
                              <Button color="primary" className="" onClick={() => {
                                if(recipientDate === ""){
                                  toast.error("Please select a date");
                                  return;
                                }
                                fetchRecipient();
                              }}>Search</Button>
                            </div> */}
                          </div>
                          <div className=" d-flex align-items-center justify-content-center gap-2">
                            <label htmlFor="tag" className="form-label"> Tags: </label>
                              <select
                                id="tag"
                                className="form-select"
                                value={receiverTag}
                                onChange={(event) => setReceiverTag(event.target.value)}
                              >
                                <option value="All">All</option>
                                {tags?.map(tag => (
                                  <option key={tag?._id} value={tag?._id}>
                                    {tag?.name}
                                  </option>
                                ))}
                              </select>
                          </div>
                          <div className=" mt-2 px-4 py-2" style={{ 
                            maxHeight: '450px', overflowY: 'auto', 
                            border: '2px solid #e5e5e5', borderRadius: '15px',
                            scrollbarColor: '#e5e5e5 #f5f5f5',
                            scrollbarWidth: 'thin'
                           }}>
                              {
                                dataRecipient.length === 0 ?
                                  <div className="text-center">  
                                    <h6>No Data Found</h6>
                                  </div>
                                : dataRecipient.map((member, index) => {
                                  return (
                                    <div className="d-flex  align-items-center gap-2" key={index}>
                                      <input type="radio" name="member" value={{
                                        _id: member?._id,
                                        memberId: member?.memberId
                                      }} onChange={
                                        (event) => {
                                          console.log("event", member);
                                          setData({
                                            ...data,
                                            recipient: {
                                              _id: member._id,
                                              memberId: member.memberId
                                            }
                                          });
                                        }
                                      } />
                                      <label className=" mt-2 fs-5" id="member">{ member?.fname + " " + member?.lname + " ( " + member?.tokenId + " )" }</label>
                                    </div>
                                  )
                                })
                              }
                          </div>
                        </div>
                        <div className=" w-full w-full d-flex flex-column gap-2">
                          <div className=" d-flex w-full justify-content-between flex-column align-item-center gap-2">
                            {/* <div className=" d-flex w-full justify-content-between align-item-center gap-2">
                              <input
                                type="date"
                                id="startDate"
                                className="form-control w-full"
                                value={senderDate}
                                onChange={(event) => setSenderDate(event.target.value)}
                              />
                              <Button color="primary" className="" onClick={() => {
                                if(senderDate === ""){
                                  toast.error("Please select a date");
                                  return;
                                }
                                fetchSenders();
                              
                              }}>Search</Button>
                            </div> */}
                              <div className=" d-flex align-items-center justify-content-center gap-2">
                                <label htmlFor="tag" className="form-label"> Tags: </label>
                                <select
                                  id="tag"
                                  className="form-select"
                                  value={senderTag}
                                  onChange={(event) => setSenderTag(event.target.value)}
                                >
                                  <option value="All">All</option>
                                  {tags?.map(tag => (
                                    <option key={tag?._id} value={tag?._id}>
                                      {tag?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                          </div>
                          <div className=" mt-2 px-4 py-2" style={{ 
                            maxHeight: '450px', overflowY: 'auto', 
                            border: '2px solid #e5e5e5', borderRadius: '15px',
                            scrollbarColor: '#e5e5e5 #f5f5f5',
                            scrollbarWidth: 'thin'
                          }}>
                              {
                                  dataSenders.length === 0 ? 
                                  <div className="text-center">  
                                    <h6>No Data Found</h6>
                                  </div>
                                  :
                                  dataSenders.map((member, index) => {
                                    return (
                                      <div className="d-flex  align-items-center gap-2" key={index}>
                                        <input type="checkbox" name="member" 
                                          value={
                                            {
                                              _id: member?._id,
                                              memberId: member?.memberId
                                            }
                                          } 
                                          onChange={() => {
                                            if(data.senders.find((sender) => sender._id === member._id)){
                                              setData({
                                                ...data,
                                                senders: [...data.senders].filter((sender) => sender._id !== member._id)
                                              });
                                            }
                                            else{
                                              setData({
                                                ...data,
                                                senders: [...data.senders, member]
                                              });
                                            }
                                          }}
                                          checked={data.senders.find((sender) => sender._id === member._id)}
                                        />
                                        <label className=" mt-2 fs-5">
                                          { member?.fname + " " + member?.lname + " ( " + member?.tokenId + " )" }
                                        </label>
                                      </div>
                                    )
                                  })
                              }
                          </div>
                        </div>
                      </div>
                      <div className=" w-full d-flex gap-2 justify-content-center">
                        <Button color="success" className="" onClick={sendHelpToMember}>
                          Assign SH
                        </Button>
                        <Button color="danger" className="" onClick={sendHelpToAdmin}>
                          Assign to Admin
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

AssignMembers.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default AssignMembers;