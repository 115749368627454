import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

export const getCustomers = (enquiry,page) => get(url.CUSTOMERS + "?enquiry=" + enquiry + "&page=" + page);

export const getMembers = (page, type) => get(url.GET_MEMBERS_API + `?page=${page}` + `&type=${type}`);
export const getMemberDetailsById =  memberId => get(url.GET_MEMBER_DETAILS_API + `/${memberId}`);
export const deleteMember = memberId => del(url.DELETE_MEMBER_API + `${memberId}`);
export const getMemberTokens = memberId => get(url.GET_MEMBER_TOKENS_API + `/${memberId}`);
export const getGH = memberId => get(url.GET_GH_API + `/${memberId}`);
export const getPinEnquiries = (page) => get(url.GET_PIN_ENQUIRIES_API + `?page=${page}`);
export const getMemberIDs = (page, startDate, endDate, date) => get(url.GET_MEMBER_IDS_API + `?page=${page}` + `&startDate=${startDate}` + `&endDate=${endDate} ` + `&date=${date}`);