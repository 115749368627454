import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getMemberIDs } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    Card,
    CardBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { formatDate } from "helpers/date_helper";
import { useLocation } from "react-router-dom";

function IDs() {

    //meta title
    document.title = "Member ID's";
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const date = searchParams.get("date");
    const dispatch = useDispatch();
    const [pageNumber, setPage] = useState(1);

    const selectMemberState = (state) => state.Member;
    const MemberProperties = createSelector(
      selectMemberState,
      (Member) => ({
        memberIds: Member.memberIds,
        totalMemberIds: Member.totalMemberIds,
      })
    );
    const { memberIds, totalMemberIds } = useSelector(MemberProperties);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getMemberIDs(pageNumber, undefined, undefined, date));
    }, [dispatch, pageNumber, date]);
    

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'fname',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    console.log("cellProps", cellProps?.row?.original?.fname);
                    return <>
                        {cellProps?.row?.original?.fname + " " + cellProps?.row?.original?.lname}
                    </>;
                }
            },
            {
                Header: 'Mobile',
                accessor: 'mobile',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.mobile}
                    </>;
                }
            },
            {
                Header: "Total ID's Count",
                accessor: 'count',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.count}
                    </>;
                }
            },
            // {
            //     Header: 'Action',
            //     accessor: 'action',
            //     width: '10%',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //       return (
            //         <div>
            //           <Button
            //             color="primary"
            //             onClick={
            //               () => handleMemberClick(cellProps?.row?.original)
            //             }
            //           >
            //             View
            //           </Button>
            //         </div>
            //       );
            //     }
            //   },
        ],
        []
    );
    return (
        <React.Fragment>
            
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem={`Member ID's on - ${formatDate(date)}`} />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                        :
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={memberIds || []}
                                            isGlobalFilter={true}
                                            handleOrderClicks={() => { }}
                                            customPageSize={10}
                                            // isPagination={true}
                                            filterable={false}
                                            iscustomPageSizeOptions={true}
                                            tableClass="align-middle table-nowrap table-check"
                                            theadClass="table-light"
                                            pagination="pagination pagination-rounded justify-content-end mb-2"
                                            isCustomPagination={true}
                                            setPage={setPage}
                                            pageNumber={pageNumber}
                                            totals={totalMemberIds}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
IDs.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};


export default IDs;