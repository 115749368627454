import { VIDEO_KYC_STATUS } from 'common/data/types'
import PropTypes from 'prop-types'
import React from "react"
import { Modal, ModalBody } from "reactstrap"

const VideoKYCModal = ({ show, title, onApprove, onReject, onCloseClick, imageSrc, videoSrc, status }) => {
  console.log("imageSrc", videoSrc)
  return (
    <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="avatar-sm mb-2 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-video-account" style={{fontSize: "35px"}}></i>
              </div>
          </div>
            <h1 className="text-muted font-size-16 mb-4">{title}</h1>
          <div className='d-flex gap-2'>
            <img src={imageSrc} alt="memberProfilePic" className="img-fluid p-2" width={"300px"} height={"350px"}
              style={{
                border: '2px solid #e5e5e5', 
                borderRadius: '15px',
              }}
            />
            <video width="100%" height="350px" controls
              className='p-2'
              style={{
                border: '2px solid #e5e5e5', 
                borderRadius: '15px',
              }}
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="hstack gap-2 justify-content-center mb-0 mt-4">
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Close</button>
            {
              status === VIDEO_KYC_STATUS.PENDING && (
                <>
                  <button type="button" className="btn btn-success" onClick={onApprove}>Approve</button>
                  <button type="button" className="btn btn-danger" onClick={onReject}>Reject</button>
                </>
              )
            }
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

VideoKYCModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  videoSrc: PropTypes.string,
}

export default VideoKYCModal
