import { call, put, takeEvery } from "redux-saga/effects";

// Member Redux States
import {
  GET_MEMBERS,
  GET_MEMBER_DETAILS,
  ON_DELETE_MEMBER,
  GET_MEMBER_TOKENS,
  GET_GH,
  GET_PIN_ENQUIRIES,
  GET_MEMBER_IDS
} from "./actionTypes";

import {
  getMembersSuccess,
  getMembersFail,
  getMemberDetailsSuccess,
  getMemberDetailsFail,
  deleteMemberSuccess,
  deleteMemberFail,
  getMemberTokensSuccess,
  getMemberTokensFail,
  getGHSuccess,
  getGHFail,
  getPinEnquirySuccess,
  getPinEnquiryFail,
  getMemberIDsSuccess,
  getMemberIDsFail
} from "./actions";

import {
  getMembers,
  getMemberDetailsById,
  deleteMember,
  getMemberTokens,
  getGH,
  getPinEnquiries,
  getMemberIDs
} from "helpers/member_helper";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchMembers({ payload: { page, type} }) {
  try {
    const response = yield call(getMembers, page, type);
    yield put(getMembersSuccess(response));
  } catch (error) {
    console.log("error", error);
    yield put(getMembersFail(error));
  }
}

function* fetchMemberIDs({ payload: {page, startDate, endDate, date} }) {
  try {
    const response = yield call(getMemberIDs, page, startDate, endDate, date);
    yield put(getMemberIDsSuccess(response));
  } catch (error) {
    yield put(getMemberIDsFail(error));
  }
}

function* onGetMemberDetails({ payload: memberId }) { 
  try {
    const response = yield call(getMemberDetailsById, memberId);
    yield put(getMemberDetailsSuccess(response));
  } catch (error) {
    yield put(getMemberDetailsFail(error));
  }
}

function* onDeleteMember({ payload: memberId }) {
  try {
    const response = yield call(deleteMember, memberId);
    yield put(deleteMemberSuccess(response));
    toast.success("Member Delete Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteMemberFail(error));
    const msg = error?.response?.data?.message || "Member Delete Failed";
    toast.error(msg, { autoClose: 2000 });
  }
}

function* onGetMemberTokens({ payload: memberId }) {
  try {
    const response = yield call(getMemberTokens, memberId);
    yield put(getMemberTokensSuccess(response));
  } catch (error) {
    yield put(getMemberTokensFail(error));
  }
}

function* onGetGH({ payload: memberId }) {
  try {
    const response = yield call(getGH, memberId);
    console.log(response)
    yield put(getGHSuccess(response));
  } catch (error) {
    console.log(error)
    yield put(getGHFail(error));
  }
}

function* onGetPinEnquiry({ payload:  page }) {
  try {
    const response = yield call(getPinEnquiries, page);
    yield put(getPinEnquirySuccess(response));
  } catch (error) {
    yield put(getPinEnquiryFail(error));
  }
}

function* memberSaga() {
  yield takeEvery(GET_MEMBERS, fetchMembers);
  yield takeEvery(GET_MEMBER_DETAILS, onGetMemberDetails);
  yield takeEvery(ON_DELETE_MEMBER, onDeleteMember);
  yield takeEvery(GET_MEMBER_TOKENS, onGetMemberTokens);
  yield takeEvery(GET_GH, onGetGH);
  yield takeEvery(GET_PIN_ENQUIRIES, onGetPinEnquiry);
  yield takeEvery(GET_MEMBER_IDS, fetchMemberIDs);
}

export default memberSaga;
