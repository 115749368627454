import { call, put, takeEvery } from "redux-saga/effects";

// Customer Redux States
import {
  CHANGE_ORDER_STATUS,
  GET_DUE_RETURN_ORDERS,
  GET_ONGOING_ORDERS,
  GET_PREBOOKING_ORDERS,
  GET_UPCOMING_ORDERS,
  GET_ALL_ORDERS,
  CANCEL_ORDER,
  GET_CANCELLED_ORDERS
} from "./actionTypes";
import {
  changeOrderStatusFail,
  changeOrderStatusSuccess,
  getDueReturnFail,
  getDueReturnSuccess,
  getOngoingFail,
  getOngoingSuccess,
  getPreBookingFail,
  getPreBookingSuccess,
  getUpcomingFail,
  getUpcomingSuccess,
  getAllOrdersFail,
  getAllOrdersSuccess,
  cancelOrderSuccess,
  cancelOrderFail,
  getCancelledOrdersSuccess,
  getCancelledOrdersFail
} from "./actions";

//Include Both Helper File with needed methods
import {
  changeOrderStatus,
  getDueReturns,
  getOngoingOrders,
  getPreBookings,
  getUpcomingOrders,
  getAllOrdersAPI,
  cancelOrder,
  getCancelledOrdersAPI
} from "helpers/order_helper";
// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchAllOrders({ payload: {page, startDate, endDate} }) {
  try {
    const response = yield call(getAllOrdersAPI, page, startDate, endDate);
    yield put(getAllOrdersSuccess(response));
  } catch (error) {
    yield put(getAllOrdersFail(error));
  }
}

function* fetchUpcomingOrders({ payload: {page, startDate, endDate} }) {
  try {
    const response = yield call(getUpcomingOrders, page, startDate, endDate);
    yield put(getUpcomingSuccess(response));
  } catch (error) {
    yield put(getUpcomingFail(error));
  }
}

function* fetchOngoingOrders({ payload: {page, startDate, endDate} }) {
  try {
    const response = yield call(getOngoingOrders,page,startDate, endDate);
    yield put(getOngoingSuccess(response));
  } catch (error) {
    yield put(getOngoingFail(error));
  }
}

function* fetchDuereturnOrders({ payload: {page, startDate, endDate} }) {
  try {
    const response = yield call(getDueReturns,page,startDate, endDate);
    yield put(getDueReturnSuccess(response));
  } catch (error) {
    yield put(getDueReturnFail(error));
  }
}

function* fetchPreBookings({ payload: {page, startDate, endDate} }) {
  try {
    const response = yield call(getPreBookings,page,startDate, endDate);
    yield put(getPreBookingSuccess(response));
  } catch (error) {
    yield put(getPreBookingFail(error));
  }
}

function* onChangeOrderStatus({ payload: { orderIds, status } }) {
  try {
    const response = yield call(changeOrderStatus, {orderIds, status});
    yield put(changeOrderStatusSuccess(response));
    toast.success("Order Status Changed Successfully");
  } catch (error) {
    yield put(changeOrderStatusFail(error));
  }
}

function* onCancelOrder({ payload: { orderId, reason } }) {
  try {
    const response = yield call(cancelOrder, orderId, reason);
    yield put(cancelOrderSuccess(response));
    toast.success("Order Cancelled Successfully");
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong";
    toast.error(errorMessage);
    yield put(cancelOrderFail(error));
  }
}

function* fetchCancelledOrders({ payload: {page, startDate, endDate} }) {
  try {
    const response = yield call(getCancelledOrdersAPI, page, startDate, endDate);
    yield put(getCancelledOrdersSuccess(response));
  } catch (error) {
    yield put(getCancelledOrdersFail(error));
  }
}


function* OrderSaga() {
  yield takeEvery(GET_ALL_ORDERS, fetchAllOrders);
  yield takeEvery(GET_UPCOMING_ORDERS, fetchUpcomingOrders);
  yield takeEvery(GET_ONGOING_ORDERS, fetchOngoingOrders);
  yield takeEvery(GET_DUE_RETURN_ORDERS, fetchDuereturnOrders);
  yield takeEvery(GET_PREBOOKING_ORDERS, fetchPreBookings);
  yield takeEvery(CHANGE_ORDER_STATUS, onChangeOrderStatus);
  yield takeEvery(CANCEL_ORDER, onCancelOrder);
  yield takeEvery(GET_CANCELLED_ORDERS, fetchCancelledOrders);
}

export default OrderSaga;
