import React, { useState } from "react";
import propTypes from 'prop-types';

import {
    Button,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    Table
} from "reactstrap";
import { formatDate } from "helpers/date_helper";
import SSModal from "./SSModal";
import { SH_GH_TYPES } from "common/data/types";

function GetHelpOffCanvasComponent(props) {
    const [imageSrc, setImageSrc] = useState(null);

    const onViewClick = (src) => {
        if(imageSrc){
            setImageSrc(null);
        } else{
            setImageSrc(src);
        }
    }
    const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
    return (
        <div>
            <Offcanvas
                zIndex={9999}
                direction="end"
                scrollable
                isOpen={props.isCanvasOpen}
                toggle={props.toggleCanvas}
                
            >
                <OffcanvasHeader
                    closeAriaLabel="Close"
                    className="d-flex justify-content-between"
                    toggle={props.toggleCanvas}
                >
                    Get Help Details
                </OffcanvasHeader>
                <OffcanvasBody>
{                
}                
                <p>GH Received From: </p>
                {
                        props.selectedGH?.sendHelp?.rejectionLimit > 0 &&
                        <p className="text-danger">*Already Rejected {props.selectedGH?.sendHelp?.rejectionLimit} times</p>
                }
                <Table>
                    <tbody>
                        {
                            props.selectedGH?.sendHelp?.screenShot &&
                            <>
                                <tr>
                                    <td>Screen Shot</td>
                                    <td>
                                        <Button
                                            color="primary"
                                            onClick={() => onViewClick(props.selectedGH?.sendHelp?.screenShot)}
                                        >
                                            View
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    {
                                        imageSrc &&
                                            <img src={
                                                imageSrc?.includes("s3.amazonaws.com") ?
                                                imageSrc : imageBaseUrl + imageSrc?.replace("uploads", "upload")
                                            } alt="screenShot" className="img-fluid p-2" width={"500px"} height={"550px"}
                                                style={{
                                                    border: '2px solid #e5e5e5',
                                                    borderRadius: '15px',
                                                }}
                                            />
                                    }
                                </tr>
                            </>
                        }
                        <tr>
                            <td>First Name</td>
                            <td>{props.selectedGH?.memberId?.fname}</td>
                        </tr>
                        <tr>
                            <td>Last Name</td>
                            <td>{props.selectedGH?.memberId?.lname}</td>
                        </tr>
                        <tr>
                            <td>Mobile</td>
                            <td>{props.selectedGH?.memberId?.mobile}</td>
                        </tr>
                        <tr>
                            <td>UPI ID</td>
                            <td>{props.selectedGH?.memberId?.upiId}</td>
                        </tr>
                        <tr>
                            <td>Mobile Verified</td>
                            <td>
                                {
                                    props.selectedGH?.memberId?.isMobileVerified ?
                                    <span className="badge bg-success p-2">Verified</span> :
                                    <span className="badge bg-danger p-2">Not Verified</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Registered On</td>
                            <td>{formatDate(props.selectedGH?.memberId?.createdAt)}</td>
                        </tr>
                        {
                            props.selectedGH?.sendHelp?.status === SH_GH_TYPES.APPROVAL_PENDING &&
                            <>
                                <tr>
                                    <td>Approve</td>
                                    <td>
                                        <Button color="success"
                                            onClick={() => props.onChangeClick(SH_GH_TYPES.COMPLETED)}
                                        >
                                            Approve
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Reject</td>
                                    <td>
                                        <Button color="danger"
                                            onClick={() => props.onChangeClick(SH_GH_TYPES.REJECTED)}
                                        >
                                            Reject
                                        </Button>
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </Table>
                </OffcanvasBody>
            </Offcanvas>
        </div>
    );
}

GetHelpOffCanvasComponent.propTypes = {
    selectedGH : propTypes.object,
    isCanvasOpen: propTypes.bool, 
    toggleCanvas: propTypes.func,
    onChangeClick: propTypes.func,
};

export default GetHelpOffCanvasComponent;

