// Get Members
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAIL = "GET_MEMBERS_FAIL";

// Get Member IDs
export const GET_MEMBER_IDS = "GET_MEMBER_IDS";
export const GET_MEMBER_IDS_SUCCESS = "GET_MEMBER_IDS_SUCCESS";
export const GET_MEMBER_IDS_FAIL = "GET_MEMBER_IDS_FAIL";

// Get Member Details
export const GET_MEMBER_DETAILS = "GET_MEMBER_DETAILS";
export const GET_MEMBER_DETAILS_SUCCESS = "GET_MEMBER_DETAILS_SUCCESS";
export const GET_MEMBER_DETAILS_FAIL = "GET_MEMBER_DETAILS_FAIL";

// Delete Member
export const ON_DELETE_MEMBER = "ON_DELETE_MEMBER";
export const ON_DELETE_MEMBER_SUCCESS = "ON_DELETE_MEMBER_SUCCESS";
export const ON_DELETE_MEMBER_FAIL = "ON_DELETE_MEMBER_FAIL";

// Member token
export const GET_MEMBER_TOKENS = "GET_MEMBER_TOKENS";
export const GET_MEMBER_TOKENS_SUCCESS = "GET_MEMBER_TOKENS_SUCCESS";
export const GET_MEMBER_TOKENS_FAIL = "GET_MEMBER_TOKENS_FAIL";

// Get GH
export const GET_GH = "GET_GH";
export const GET_GH_SUCCESS = "GET_GH_SUCCESS";
export const GET_GH_FAIL = "GET_GH_FAIL";

// Get PIN Enquiries
export const GET_PIN_ENQUIRIES = "GET_PIN_ENQUIRIES";
export const GET_PIN_ENQUIRIES_SUCCESS = "GET_PIN_ENQUIRIES_SUCCESS";
export const GET_PIN_ENQUIRIES_FAIL = "GET_PIN_ENQUIRIES_FAIL";