import {
  GET_MEMBERS,
  GET_MEMBERS_FAIL,
  GET_MEMBERS_SUCCESS,
  GET_MEMBER_IDS,
  GET_MEMBER_IDS_SUCCESS,
  GET_MEMBER_IDS_FAIL,
  GET_MEMBER_DETAILS,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_DETAILS_FAIL,
  ON_DELETE_MEMBER,
  ON_DELETE_MEMBER_FAIL,
  ON_DELETE_MEMBER_SUCCESS,
  GET_MEMBER_TOKENS,
  GET_MEMBER_TOKENS_SUCCESS,
  GET_MEMBER_TOKENS_FAIL,
  GET_GH,
  GET_GH_SUCCESS,
  GET_GH_FAIL,
  GET_PIN_ENQUIRIES,
  GET_PIN_ENQUIRIES_SUCCESS,
  GET_PIN_ENQUIRIES_FAIL
} from "./actionTypes"


export const getMembers = (page, type) => ({
  type: GET_MEMBERS,
  payload: {page, type}
});

export const getMembersSuccess = memberId => ({
  type: GET_MEMBERS_SUCCESS,
  payload: memberId,
});

export const getMembersFail = error => ({
  type: GET_MEMBERS_FAIL,
  payload: error,
});

export const getMemberIDs = (page, startDate, endDate, date) => ({
  type: GET_MEMBER_IDS,
  payload: {page, startDate, endDate, date}
});

export const getMemberIDsSuccess = memberIds => ({
  type: GET_MEMBER_IDS_SUCCESS,
  payload: memberIds,
});

export const getMemberIDsFail = error => ({
  type: GET_MEMBER_IDS_FAIL,
  payload: error,
});

export const getMemberDetails = memberId => ({
  type: GET_MEMBER_DETAILS,
  payload: memberId,
});

export const getMemberDetailsSuccess = member => ({
  type: GET_MEMBER_DETAILS_SUCCESS,
  payload: member,
});

export const getMemberDetailsFail = error => ({
  type: GET_MEMBER_DETAILS_FAIL,
  payload: error,
});

export const deleteMember = memberId => ({
  type: ON_DELETE_MEMBER,
  payload: memberId,
});

export const deleteMemberSuccess = memberId => ({
  type: ON_DELETE_MEMBER_SUCCESS,
  payload: memberId,
});

export const deleteMemberFail = error => ({
  type: ON_DELETE_MEMBER_FAIL,
  payload: error,
});

export const getMemberTokens = memberId => ({
  type: GET_MEMBER_TOKENS,
  payload: memberId,
});

export const getMemberTokensSuccess = tokens => ({
  type: GET_MEMBER_TOKENS_SUCCESS,
  payload: tokens,
});

export const getMemberTokensFail = error => ({
  type: GET_MEMBER_TOKENS_FAIL,
  payload: error,
});

export const getGH = memberId => ({
  type: GET_GH,
  payload: memberId,
});

export const getGHSuccess = gh => ({
  type: GET_GH_SUCCESS,
  payload: gh,
});

export const getGHFail = error => ({
  type: GET_GH_FAIL,
  payload: error,
});

export const getPinEnquiries = page => ({
  type: GET_PIN_ENQUIRIES,
  payload: page
});

export const getPinEnquirySuccess = pinEnquiries => ({
  type: GET_PIN_ENQUIRIES_SUCCESS,
  payload: pinEnquiries,
});

export const getPinEnquiryFail = error => ({
  type: GET_PIN_ENQUIRIES_FAIL,
  payload: error,
});