import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
    getCustomers as onGetCustomers,
    onGetCustomerOrders,
    getMemberDetails,
    getMemberTokens
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Button,
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardText,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import OffCanvasComponent from "./OffCanvasComponent";
import { formatDate } from "helpers/date_helper";
import Spinners from "components/Common/Spinner";
import { SH_GH_TYPES } from "common/data/types";


function MemberDetails() {

    //meta title
    document.title = "Customer Details";
    const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
    const [isEdit, setIsEdit] = useState(false);
    const[selectedOrder, setSelectedOrder] = useState({});

    const dispatch = useDispatch();

    const selectMemberState = (state) => state.Member;
    const MemberProperties = createSelector(
        selectMemberState,
        (Member) => ({
            member: Member.member,
            tokens: Member.tokens,
            loading: Member.loading,
        })
    );

    const selectCustomerState = (state) => state.Customer;
    const CustomerProperties = createSelector(
        selectCustomerState,
        (Customer) => ({
            customer: Customer.customer,
            orders: Customer.orders,
            loading: Customer.loading,
        })
    );

    const { customer, orders, } = useSelector(CustomerProperties);
    const { member, tokens, loading } = useSelector(MemberProperties);
    const location = useLocation(); //from react-router-dom
    const searchParams = new URLSearchParams(location.search);
    const memberId = searchParams.get("memberId");

    useEffect(() => {
        dispatch(getMemberDetails(memberId));
        dispatch(getMemberTokens(memberId));
    }, [dispatch,memberId]);

    useEffect(() => {
        if (!isEmpty(customer) && !!isEdit) {
            setIsEdit(false);
        }

    }, [customer]);

    const [isCanvasOpen, setIsCanvasOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState({});

    const toggleCanvas = () => {
        setIsCanvasOpen(!isCanvasOpen);
    };

    const onViewClick= (member) => {
        toggleCanvas();
        setSelectedMember(member);
    }

    const columnsForOrders = useMemo(
        () => [
            {
                Header: 'Token ID',
                accessor: "token",
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    return <>{row?.original?.tokenId ?? " "} </>;
                },
            },
            {
                Header: 'Created Date',
                accessor: 'createdDate',
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    const createdDate = formatDate(row?.original?.createdAt);
                    return <> {createdDate} </>;
                },
            },
            {
                Header: 'Active',
                accessor: 'active',
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    return <> {
                        row.original.isActive ?
                        <span className="badge bg-success p-2">Active</span> :
                        <span className="badge bg-danger p-2">Inactive</span>
                    } </>;
                },
            },
            {
                Header: 'Send Help',
                accessor: 'sendHelp',
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    return <> {
                        row?.original?.name ?
                        row?.original?.name : "-"
                    } </>;
                },
            },
            {
                Header: 'Send Help Status',
                accessor: 'sendHelpStatus',
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    return <> {
                        row?.original?.sendHelpStatus ?
                        <>
                            {row?.original?.sendHelpStatus === SH_GH_TYPES.COMPLETED && 
                            <span className="badge bg-success p-2">Completed</span>}
                            {row?.original?.sendHelpStatus === SH_GH_TYPES.APPROVAL_PENDING &&
                            <span className="badge bg-warning p-2">Approval Pending</span>}
                            {row?.original?.sendHelpStatus === SH_GH_TYPES.PAYMENT_PENDING &&
                            <span className="badge bg-info p-2">Payment Pending</span>}
                            {row?.original?.sendHelpStatus === SH_GH_TYPES.REJECTED &&
                            <span className="badge bg-danger p-2">Rejected</span>}
                            {row?.original?.sendHelpStatus === SH_GH_TYPES.EXPIRED &&
                            <span className="badge bg-secondary p-2">Expired</span>}
                            {row?.original?.sendHelpStatus === "N/A" &&
                            <span className="">N/A</span>}
                        </> : "-"
                    } </>;
                },
            },
            {
                Header: 'Get Help 1',
                accessor: 'getHelp1',
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    return <> {
                        row?.original?.getHelp1 ?
                        <p>Assigned</p> : <p>Not Assigned</p>
                    } </>;
                },
            },
            {
                Header: 'Get Help 2',
                accessor: 'getHelp2',
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    return <> {
                        row?.original?.getHelp2 ?
                        <p>Assigned</p> : <p>Not Assigned</p>
                    } </>;
                },
            },
            // {
            //     Header: "Action",
            //     accessor: "action",
            //     width: "150px",
            //     style: {
            //         textAlign: "center",
            //         width: "10%",
            //         background: "#0000",
            //     },
            //     filterable: true,
            //     Cell: ({ row }) => {
                    
            //         return (
            //             <Button
            //                 color="primary"
            //                 onClick={() => onViewClick(row?.original)}
            //             >
            //                 View
            //             </Button>
            //         );
            //     }
            // }
        ],
        []
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Member Details" />
                    {
                        loading ? <Spinners />
                        :
                        <>
                            <Row>
                                <Col xs="12" lg="12">
                                    <Card >
                                        <Link to="/members">
                                            <Button className="position-absolute top-0 end-0 m-3">Back to Member</Button>
                                        </Link>
                                        <CardBody className="my-2">
                                            <CardTitle className="mb-3" tag="h5">Member Details</CardTitle>
                                            <CardText className=" d-sm-flex align-items-center gap-4">
                                                <Col xs="12" lg="2" 
                                                    style={{
                                                        border: '2px solid #e5e5e5', 
                                                        borderRadius: '15px',
                                                        padding: '5px',
                                                    }}
                                                >
                                                    <img src={(member?.profileImage && member?.profileImage.includes("s3.amazonaws.com") ? member?.profileImage : imageBaseUrl + member?.profileImage?.replace("uploads", "upload")
                                                    ) || `https://ui-avatars.com/api/?name=${member?.fname}+${member?.lname}&background=random&color=fff`} alt="profileImage" className="img-fluid" style={{borderRadius: '15px' }} width="100%" height="100%" />
                                                </Col>
                                                <Col className=" d-flex gap-4">
                                                    <div className="">
                                                        <div className="">
                                                        <strong>Name:</strong> {member?.fname} {member?.lname}
                                                        </div>
                                                        <hr />
                                                        <div className="">
                                                        <strong>Mobile:</strong> {member?.mobile}
                                                        </div>
                                                        <hr />
                                                        <div className="">
                                                        <strong>Epin Balance: </strong>
                                                            {member?.epinBalance}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="">
                                                        <div className="">
                                                        <strong>Registered On: </strong>
                                                            {formatDate(member?.createdAt)}
                                                        </div>
                                                        <hr />
                                                        <div className="">
                                                        <strong>Stage: </strong>
                                                            {member?.stage}
                                                        </div>
                                                        <hr />
                                                        <div className="">
                                                            <strong>Video Consent:</strong> {member?.videoKYC?.status || "N/A"}
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <div className=" d-flex flex-col align-items-start justify-content-start">
                                                        <strong>Tags:</strong> 
                                                        <div className=" d-flex flex-column gap-1">
                                                            {
                                                                member?.tags?.map((tag, index) => (
                                                                    <span key={index} className="badge bg-primary px-2 py-1">{tag?.name}</span>
                                                                ))
                                                            }
                                                        </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {/* Orders Table */}
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <CardTitle tag="h5">Orders</CardTitle>
                                            <TableContainer
                                                columns={columnsForOrders}
                                                data={tokens || []}
                                                isGlobalFilter={true}
                                                customPageSize={10}
                                                isPagination={true}
                                                filterable={false}
                                                iscustomPageSizeOptions={true}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-end mb-2"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    }
                </div>
            </div>
            <ToastContainer />
            
            <OffCanvasComponent
              isCanvasOpen={isCanvasOpen}
              toggleCanvas={toggleCanvas}
              selectedMember={selectedMember} 
             />
        </React.Fragment>
    );
}
MemberDetails.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default MemberDetails;