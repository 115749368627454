import {
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  GET_DAYS_SUCCESS,
  GET_DAYS_FAIL,
  ADD_NEW_DAY_SUCCESS,
  ADD_NEW_DAY_FAIL,
  UPDATE_DAY_SUCCESS,
  UPDATE_DAY_FAIL,
  DELETE_DAY_SUCCESS,
  DELETE_DAY_FAIL
} from "./actionTypes";

const INIT_STATE = {
  tags: [],
  days: [],
  error: {},
  loading: true
};

const Tag = (state = INIT_STATE, action) => {
  switch (action.type) {
  
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
        loading: true
      };

    case GET_TAGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_TAG_SUCCESS:
      return {
        ...state,
        tags: [...state.tags, action.payload],
      };

    case ADD_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags.map(category =>
          (category._id + '') === (action.payload._id + '')
            ? { category, ...action.payload }
            : category
        ),
      };

    case UPDATE_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags.filter(
          category => category._id !== action?.payload?._id
        ),
      };

    case DELETE_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_DAYS_SUCCESS:
      return {
        ...state,
        days: action.payload,
        loading: true
      };

    case GET_DAYS_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    case ADD_NEW_DAY_SUCCESS:
      return {
        ...state,
        days: [...state.days, action.payload],
      };

    case ADD_NEW_DAY_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_DAY_SUCCESS:
      return {
        ...state,
        days: state.days.map(day =>
          (day._id + '') === (action.payload._id + '')
            ? { day, ...action.payload }
            : day
        ),
      };

    case UPDATE_DAY_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_DAY_SUCCESS:
      return {
        ...state,
        days: state.days.filter(
          day => day._id !== action?.payload?._id
        ),
      };

    case DELETE_DAY_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Tag;
