import PropTypes from "prop-types"
import React from "react"
import { Input, Modal, ModalBody } from "reactstrap"

const PublishContainerModal = ({ show, onPublishClick, onCloseClick, isEdit, containerName, publishDate, setPublishDate, publishTime, setPublishTime, endDate, setEndDate, endTime, setEndTime, publishError, isChangePublishDate }) => {
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
            {
              isChangePublishDate ? 
              <div>
                <h3>Update Publish Date Time</h3>
                <h6 className="text-primary">Container is published already.</h6>
              </div> : <h3>Publish Container</h3>
            }
          <div className="d-flex gap-4 m-4">
            <h6>Publish Date Time</h6>
            <div className="d-flex gap-2">
              <Input
                  name="date"
                  type="date"
                  className="form-control"
                  onChange={setPublishDate}
                  value={publishDate}
                  disabled={isEdit}
              />
              <Input
                  name="time"
                  type="time"
                  className="form-control"
                  onChange={setPublishTime}
                  value={publishTime}
                  disabled={isEdit}
              />
            </div>
          </div>
          <div className="d-flex gap-4 m-4">
            <h6>Expiry Date Time</h6>
            <div className="d-flex gap-2">
              <Input
                  name="date"
                  type="date"
                  className="form-control"
                  onChange={setEndDate}
                  value={endDate}
                  disabled={isEdit}
              />
              <Input
                  name="time"
                  type="time"
                  className="form-control"
                  onChange={setEndTime}
                  value={endTime}
                  disabled={isEdit}
              />
            </div>
          </div>
          <div>
            {publishError && <p className="text-danger">End Date Time should be at least 9 hours greater than Publish Date Time.</p>}
          </div>
          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-primary"
              onClick={onPublishClick}
            >
              Publish Container
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseClick}
            >
              Close
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

PublishContainerModal.propTypes = {
  onCloseClick: PropTypes.func,
  onPublishClick: PropTypes.func,
  show: PropTypes.any,
}

export default PublishContainerModal
