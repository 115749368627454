import {
  GET_FAQS,
  GET_FAQS_FAIL,
  GET_FAQS_SUCCESS,
  ADD_NEW_FAQ,
  ADD_NEW_FAQ_FAIL,
  ADD_NEW_FAQ_SUCCESS,
  UPDATE_FAQ,
  UPDATE_FAQ_FAIL,
  UPDATE_FAQ_SUCCESS,
  DELETE_FAQ,
  DELETE_FAQ_FAIL,
  DELETE_FAQ_SUCCESS
} from "./actionTypes"


// FAQs
export const getFAQs = (filter) => ({
  type: GET_FAQS,
  payload: filter,
})

export const getFAQsSuccess = faqs => ({
  type: GET_FAQS_SUCCESS,
  payload: faqs,
})

export const getFAQsFail = error => ({
  type: GET_FAQS_FAIL,
  payload: error,
})

export const addNewFAQ = category => ({
  type: ADD_NEW_FAQ,
  payload: category,
})

export const addFAQSuccess = category => ({
  type: ADD_NEW_FAQ_SUCCESS,
  payload: category,
})

export const addFAQFail = error => ({
  type: ADD_NEW_FAQ_FAIL,
  payload: error,
})

export const updateFAQ = category => ({
  type: UPDATE_FAQ,
  payload: category,
})

export const updateFAQSuccess = category => ({
  type: UPDATE_FAQ_SUCCESS,
  payload: category,
})

export const updateFAQFail = error => ({
  type: UPDATE_FAQ_FAIL,
  payload: error,
})

export const deleteFAQ = category => ({
  type: DELETE_FAQ,
  payload: category,
})

export const deleteFAQSuccess = category => ({
  type: DELETE_FAQ_SUCCESS,
  payload: category,
})

export const deleteFAQFail = error => ({
  type: DELETE_FAQ_FAIL,
  payload: error,
})
