import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import {
    getDays as onGetDays,
    addNewDay as onAddNewDay,
    updateDay as onUpdateDay,
    deleteDay as onDeleteDay
} from "store/actions";


//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { formatDate } from "helpers/date_helper";

function DayTable() {

    //meta title
    document.title = "Master >> DayTable";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [day, setDay] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [published, setPublished] = useState(false);

    const formatDateForInput = (date) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            day: (day && day.day) || '',
            date: (day && formatDateForInput(day?.date)) || '',
            systemIdCreationLimit: (day && day.systemIdCreationLimit) || '',
            published: (day && day.published) || '',
        },

        validationSchema: Yup.object({
            day: Yup.string(),
            date: Yup.string().required("Date is required"),
            systemIdCreationLimit: Yup.string().required("Max Limit is required"),
            published: Yup.boolean()
        }),

        onSubmit: (values) => {
            if (isEdit) {
                const updateDay = {
                    _id: day._id,
                    published: published,
                    systemIdCreationLimit: values.systemIdCreationLimit,
                };
                dispatch(onUpdateDay(updateDay));
                validation.resetForm();
            } else {
                const newDay = {
                    date: values.date,
                    systemIdCreationLimit: values.systemIdCreationLimit,
                    published: published
                };
                dispatch(onAddNewDay(newDay));
                validation.resetForm();
            }
            toggle();
        },
    });

    const dispatch = useDispatch();

    const selectTagState = (state) => state.Tag;
    const TagProperties = createSelector(
        selectTagState,
        (Tag) => ({
            tags: Tag.tags,
            days: Tag.days
        })
    );

    const { days } = useSelector(TagProperties);
    console.log(days);

    useEffect(() => {
        dispatch(onGetDays());
    }, [dispatch]);


    const toggle = () => {
        if (modal) {
            setModal(false);
            setDay(null);
        } else {
            setModal(true);
        }
    };


    const handleDayClick = arg => {
        const day = arg;
        setDay(day);
        setPublished(day?.published);
        setIsEdit(true);
        toggle();
    };

    const onClickDelete = (day) => {
        setDay(day);
        setDeleteModal(true);
    };

    const handleDayDelete = () => {
        if(day && day._id) {
            dispatch(onDeleteDay(day._id));
            setDeleteModal(false);
        }
    };

    const handleAddDayClick = () => {
        setIsEdit(false);
        setPublished(false);
        setDay(null);
        toggle();
    };


    const columns = useMemo(
        () => [
            {
                Header: 'Day',
                accessor: 'day',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.day}
                    </>;
                }
            },
            {
                Header: 'Date',
                accessor: 'date',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    const date = formatDate(cellProps?.row?.original?.date)
                    const isHighlighted = date === formatDate(new Date());
                    return <>
                        <span className={`${isHighlighted && "bg-primary badge p-2"}`}>
                            {date}
                        </span>
                    </>;
                }
            },
            {
                Header: 'Max ID Creation Limit',
                accessor: 'maxLimit',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.systemIdCreationLimit}
                    </>;
                }
            },
            {
                Header: 'Published',
                accessor: 'published',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.published ? 
                            <span className="badge bg-success p-2">Yes</span> : 
                            <span className="badge bg-danger p-2">No</span>
                        }
                    </>;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Button
                                type="button"
                                color="primary"
                                className="btn btn-primary btn-sm"
                                onClick={() => handleDayClick(cellProps.row.original)}
                            >
                                Manage
                            </Button>
                            {/* <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const day = cellProps.row.original;
                                    onClickDelete(day);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link> */}
                        </div>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDayDelete}
                onCloseClick={() => setDeleteModal(false)}
                title="Day Entry"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Masters" breadcrumbItem="Day Table" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>

                                            <TableContainer
                                                title={"Day"}
                                                columns={columns}
                                                data={days || []}
                                                isGlobalFilter={true}
                                                // isAddOptions={true}
                                                handleOrderClicks={handleAddDayClick}
                                                customPageSize={11}
                                                isPagination={true}
                                                filterable={false}
                                                iscustomPageSizeOptions={true}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-end mb-2"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit Day Table" : "Add Day Table"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">


                                        {
                                            isEdit &&
                                            <div className="mb-3">
                                                <Label>Day</Label>
                                                <Input
                                                    name="day"
                                                    type="text"
                                                    placeholder="Enter Day"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                    disabled={isEdit}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.day || ''}
                                                    invalid={
                                                        validation.touched.day && validation.errors.day ? true : false
                                                    }
                                                />
                                                {validation.touched.day && validation.errors.day ? (
                                                    <FormFeedback type="invalid">{validation.errors.day}</FormFeedback>
                                                ) : null}
                                            </div>
                                        }
                                        
                                        <div className="mb-3">
                                            <Label>Date</Label>
                                            <Input
                                                name="date"
                                                type="date"
                                                className="form-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.date}
                                                disabled={isEdit}
                                            />
                                            {validation.touched.date && validation.errors.date ? (
                                                <FormFeedback type="invalid" className="d-block">{validation.errors.date}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label>Max Limit</Label>
                                            <Input
                                                name="systemIdCreationLimit"
                                                type="text"
                                                placeholder="Enter Max Limit"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                disabled={isEdit}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.systemIdCreationLimit || ''}
                                                invalid={
                                                    validation.touched.systemIdCreationLimit && validation.errors.systemIdCreationLimit ? true : false
                                                }
                                            />
                                            {validation.touched.systemIdCreationLimit && validation.errors.systemIdCreationLimit ? (
                                                <FormFeedback type="invalid">{validation.errors.systemIdCreationLimit}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3 d-flex  gap-2">
                                            <Label>Publish: </Label>
                                            <div className="form-check form-switch">
                                                <Input
                                                    name="published"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={published}
                                                    value={validation.values.published || false}
                                                    onChange={() => setPublished(!published)}
                                                />
                                                <Label className="form-check-label">{published ? "Yes" : "No"}</Label>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
DayTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default DayTable;