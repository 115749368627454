import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

export const getTags = () => get(url.TAGS);
export const addNewTag = tag => post(url.TAGS, tag);
export const updateTag = tag => put(url.TAGS + "/" + tag._id, tag);
export const deleteTag = tag => del(url.TAGS + "/" + tag);

export const getDays = () => get(url.DAYS);
export const addNewDay = day => post(url.DAYS, day);
export const updateDay = day => put(url.DAYS + "/" + day._id, day);
export const deleteDay = day => del(url.DAYS + "/" + day);