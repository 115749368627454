import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
    getMembers,
    getTags as onGetTags,
    getGH as onGetGH,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { post } from "helpers/api_helper";
import { formatDate } from "helpers/date_helper";
import { SH_GH_TYPES } from "common/data/types";
import GetHelpOffCanvasComponent from "./GetHelpOffCanvasComponent";

function GetHelp() {

    //meta title
    document.title = "Get Help";

    const [isEdit, setIsEdit] = useState(false);
    const [pageNumber, setPage] = useState(1);
    const [member, setMember] = useState(null);

    const dispatch = useDispatch();

    const selectMemberState = (state) => state.Member;
    const MemberProperties = createSelector(
        selectMemberState,
        (Member) => ({
            gh: Member.gh,
        })
    );
    const { gh } = useSelector(MemberProperties);
    console.log("gh", gh);


    const [isLoading, setLoading] = useState(true)
    const adminId = process.env.REACT_APP_ADMIN_ID;
    useEffect(() => {
        dispatch(onGetGH(adminId));
    }, [dispatch, adminId]);


    const columnsForOrders = useMemo(
        () => [
            {
                Header: 'Token ID',
                accessor: "token",
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    console.log("row", row.original);
                    return <>{row?.original?.tokenId ?? " "} </>;
                },
            },
            {
                Header: 'Sender Name',
                accessor: 'senderName',
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    return <>{row?.original?.memberId?.fname + " " + row?.original?.memberId?.lname ?? " "} </>;
                },
            },
            {
                Header: 'Assigned Date',
                accessor: 'createdDate',
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    const createdDate = formatDate(row?.original?.createdAt);
                    return <> {createdDate} </>;
                },
            },
            {
                Header: 'Get Help Status',
                accessor: 'sendHelpStatus',
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {
                    return <> {
                        row?.original?.sendHelp?.status ?
                            <>
                                {row?.original?.sendHelp?.status === SH_GH_TYPES.COMPLETED &&
                                    <span className="badge bg-success p-2">Completed</span>}
                                {row?.original?.sendHelp?.status === SH_GH_TYPES.APPROVAL_PENDING &&
                                    <span className="badge bg-warning p-2">Approval Pending</span>}
                                {row?.original?.sendHelp?.status === SH_GH_TYPES.PAYMENT_PENDING &&
                                    <span className="badge bg-info p-2">Payment Pending</span>}
                                {row?.original?.sendHelp?.status === SH_GH_TYPES.REJECTED &&
                                    <span className="badge bg-danger p-2">Rejected</span>}
                                {row?.original?.sendHelp?.status === SH_GH_TYPES.EXPIRED &&
                                    <span className="badge bg-secondary p-2">Expired</span>}
                                {row?.original?.sendHelp?.status === "N/A" &&
                                    <span className="">N/A</span>}
                            </> : "-"
                    } </>;
                },
            },
            {
                Header: "Action",
                accessor: "action",
                width: "150px",
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: ({ row }) => {

                    return (
                        <Button
                            color="primary"
                            onClick={() => handleGHClick(row?.original)}
                        >
                            View
                        </Button>
                    );
                }
            }
        ],
        []
    );

    const [isCanvasOpen, setIsCanvasOpen] = useState(false);
    const [selectedGH, setSelectedGH] = useState(null);

    const toggleCanvas = () => {
        setIsCanvasOpen(!isCanvasOpen);
    }

    const handleGHClick = (gh) => {
        setSelectedGH(gh);
        toggleCanvas();
    }

    const handleUpdateGH = async (status) => {
        try {
            const response = await post(`members/update-gh/${selectedGH?._id}`, { status });
            toast.success("Get Help updated successfully");
            dispatch(onGetGH(adminId));
            setSelectedGH(null);
            toggleCanvas();
        }
        catch (error) {
            console.log("error", error);
            let msg = error?.response?.data?.message || "Error in updating Get Help";
            toast.error(msg);
        }
    }

    return (
        <React.Fragment>


            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Get Help" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>

                                            <TableContainer
                                                columns={columnsForOrders}
                                                data={gh || []}
                                                isGlobalFilter={true}
                                                handleOrderClicks={() => { }}
                                                customPageSize={10}
                                                isPagination={true}
                                                filterable={false}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-end mb-2"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <GetHelpOffCanvasComponent
                        isCanvasOpen={isCanvasOpen}
                        toggleCanvas={toggleCanvas}
                        selectedGH={selectedGH}
                        onChangeClick={(status) => handleUpdateGH(status)}
                    />
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}

GetHelp.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};


export default GetHelp;