import PropTypes from "prop-types"
import React from "react"
import { Input, Modal, ModalBody } from "reactstrap"

const CreateContainerModal = ({ show, onCreateClick, onCloseClick, isEdit, setDate, date }) => {
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <div className="d-flex flex-column gap-4 m-4">
            <h4>Select a date to create container</h4>
            <Input
                name="date"
                type="date"
                className="form-control"
                onChange={setDate}
                value={date}
                disabled={isEdit}
            />
          </div>
          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-primary"
              onClick={onCreateClick}
            >
              Create Container
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseClick}
            >
              Close
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

CreateContainerModal.propTypes = {
  onCloseClick: PropTypes.func,
  onCreateClick: PropTypes.func,
  show: PropTypes.any,
}

export default CreateContainerModal
