import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getMembers,
  getTags as onGetTags,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { post } from "helpers/api_helper";
import moment from "moment";
import OffCanvasComponent from "./OffCanvasComponent";

function Members() {

  //meta title
  document.title = "Members";
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const [isEdit, setIsEdit] = useState(false);
  const [pageNumber, setPage] = useState(1);
  const [member, setMember] = useState(null);

  const dispatch = useDispatch();

  const selectMemberState = (state) => state.Member;
  const MemberProperties = createSelector(
    selectMemberState,
    (Member) => ({
      members: Member.members,
      totalMembers: Member.totalMembers,
    })
  );
  const { members, totalMembers } = useSelector(MemberProperties);
  console.log("members", members);

  const selectTagState = (state) => state.Tag;
  const TagProperties = createSelector(
    selectTagState,
    (Tag) => ({
      tags: Tag.tags,
    })
  );
  const { tags } = useSelector(TagProperties);
  const options = tags.map(tag => ({ label: tag.name, value: tag._id }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      fname: member ? member.fname : "",
      lname: member ? member.lname : "",
      mobile: member ? member.mobile : "",
      tags: member ? member.tags : [],
    },
    validationSchema: Yup.object({
      fname: Yup.string().required("First Name is required"),
      lname: Yup.string().required("Last Name is required"),
      mobile: Yup.string()
        .required("Mobile Number is required")
        .matches(/^[6-9]\d{9}$/, "Invalid Mobile Number"),
      tags: Yup.array().required("Tag is required").min(0, "Tag is required"),
    }),

    onSubmit: (values) => {
      const data = {
        fname: values.fname,
        lname: values.lname,
        tags: values.tags,
        _id: member?._id,
      };
      if (isEdit) {
        updateMember(data);
      }
      setIsEdit(false);
      validation.resetForm();
    },
  });


  const updateMember = async (memberData) => {
    setLoading(true);
    try {
      const response = await post(`members/update/${memberData?._id}`, memberData);
      toast.success("Member updated successfully");
      dispatch(getMembers(pageNumber));
    }
    catch (error) {
      console.log("error", error);
      let msg = error?.response?.data?.message || "Error in updating updateMember";
      toast.error(msg);
      setLoading(false);
    }
  }

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(getMembers(pageNumber));
    dispatch(onGetTags());
  }, [dispatch,pageNumber]);


  const handelUpateMemberClick = (memberData) => {
    setIsEdit(true);
    const updateMemberData = { ...memberData };
    setMember(updateMemberData);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Profile Image',
        accessor: 'profileImage',
        width: '10%',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          let profileImage = cellProps?.row?.original?.profileImage || `https://ui-avatars.com/api/?name=${cellProps?.row?.original?.fname}+${cellProps?.row?.original?.lname}&background=random&color=fff`;
          if(!profileImage.includes("s3.amazonaws.com")) 
            profileImage = imageBaseUrl + profileImage?.replace("uploads", "upload");
            console.log("profileImage", profileImage);
          return <img src={profileImage} alt="profile" className="avatar-xs rounded-circle" />;
        }
      },
      {
        Header: 'Name',
        accessor: 'fname',
        width: '10%',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <Link className="text-dark"
            to={`/member-details?memberId=${cellProps?.row?.original?._id}`}
          >
          {cellProps?.row?.original?.fname} {cellProps?.row?.original?.lname}
        </Link>
        }
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        width: '10%',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return cellProps?.row?.original?.mobile;
        }
      },
      {
        Header: 'UPI ID',
        accessor: 'upiId',
        width: '10%',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return cellProps?.row?.original?.upiId;
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: '10%',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const memberData = cellProps.row.original;
                  handelUpateMemberClick(memberData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to={`/member-details?memberId=${cellProps?.row?.original?._id}`}
              >
                <i className="mdi mdi-eye font-size-18" id="eyetooltip" />
                <UncontrolledTooltip placement="top" target="eyetooltip">
                  Member Details
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const [isCanvasOpen, setIsCanvasOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const toggleCanvas = () => {
    setIsCanvasOpen(!isCanvasOpen);
  }

  const handleMemberClick = (member) => {
    setSelectedMember(member);
    toggleCanvas();
  }

  return (
    <React.Fragment>


      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Members" />
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>

                      <TableContainer
                        columns={columns}
                        data={members || [] }
                        isGlobalFilter={true}
                        handleOrderClicks={() => { }}
                        customPageSize={10}
                        isPagination={false}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        isCustomPagination={true}
                        setPage={setPage}
                        pageNumber={pageNumber}
                        totals={totalMembers}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
          <OffCanvasComponent
              isCanvasOpen={isCanvasOpen}
              toggleCanvas={toggleCanvas}
              selectedMember={selectedMember} 
          />

          {isEdit && (
            <Modal
              isOpen={isEdit}
              role="dialog"
              autoFocus={true}
              centered={true}
              size="lg"
              id="verificationModal"
              tabIndex="-1"
              toggle={() => {
                setIsEdit(!isEdit);
                const customerData = customer;
                setSelectedCity(customerData.city);
              }}
            >
              <ModalHeader toggle={() => setIsEdit(!isEdit)}>
                Edit Customer
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label htmlFor="lname">First Name</Label>
                        <Input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="Enter Name"
                          value={validation.values.fname}
                          onChange={validation.handleChange}
                        />
                        {validation.errors.fname && validation.touched.fname && (
                          <p className="text-danger mt-1">{validation.errors.fname}</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label htmlFor="lname">Last Name</Label>
                        <Input
                          type="text"
                          id="lname"
                          name="lname"
                          placeholder="Enter Name"
                          value={validation.values.lname}
                          onChange={validation.handleChange}
                        />
                        {validation.errors.lname && validation.touched.lname && (
                          <p className="text-danger mt-1">{validation.errors.lname}</p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label htmlFor="mobile">Mobile</Label>
                        <Input
                          type="text"
                          id="mobile"
                          name="mobile"
                          placeholder="Enter Mobile No."
                          disabled={true}
                          value={validation.values.mobile}
                          onChange={
                            (e) => {
                              const inputValue = e.target.value;
                              if (inputValue === "" || /^[0-9\b]+$/.test(inputValue))
                                validation.setFieldValue("mobile", inputValue);
                            }
                          }
                        />
                        {validation.errors.mobile && validation.touched.mobile && (
                          <p className="text-danger mt-1">{validation.errors.mobile}</p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      className="col-sm-3 col-form-label">Tags</Label>
                    <Col sm={9}>
                      <Select
                        isMulti
                        className="react-select-container"
                        classNamePrefix="react-select"

                        name="tags"
                        options={options}
                        value={options.filter((tag) => validation?.values?.tags?.includes(tag.value))}
                        onChange={(selectedOptions) => {
                          validation.setFieldValue("tags", selectedOptions.map((option) => option.value));
                        }}
                      />
                      {validation.touched.tags && validation.errors.tags ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.tags}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button type="submit" color="primary">
                      Save
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => setIsEdit(!isEdit)}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Form>
              </ModalBody>
            </Modal>
          )
          }
          {/* Update Password Modal. */}
          {/* {isPasswordModalOpen && (
            <Modal
                isOpen={isPasswordModalOpen}
                role="dialog"
                autoFocus={true}
                centered={true}
                size="md"
                id="passwordModal"
                tabIndex="-1"
                toggle={() => setIsPasswordModalOpen(true)}
            >
                <ModalHeader toggle={() => setIsPasswordModalOpen(!isPasswordModalOpen)}>
                    Update Password
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                    >
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="newPassword">New Password </Label>
                                <Input
                                    type="password"
                                    id="newPassword"
                                    name="password"
                                    placeholder="Enter New Password"
                                    value={validation.values.password}
                                    onChange={validation.handleChange}
                                />
                                {validation.errors.password && validation.touched.password && (
                                    <p className="text-danger mt-1">{validation.errors.password}</p>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="confirmPassword">Confirm Password</Label>
                                <Input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    placeholder="Enter Confirm Password"
                                    value={validation.values.confirmPassword}
                                    onChange={validation.handleChange}
                                />
                                {validation.errors.confirmPassword && validation.touched.confirmPassword && (
                                    <p className="text-danger mt-1">{validation.errors.confirmPassword}</p>
                                )}
                            </FormGroup>
                        </Col>
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button type="button" onClick={() => handlePasswordUpdate(customer?._id)} color="primary"
                  disabled={!customer?._id}
                >
                  Update Password
                </Button>
                    <Button
                        type="button"
                        color="secondary"
                        onClick={() => setIsPasswordModalOpen(!isPasswordModalOpen)}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )} */}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

Members.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default Members;