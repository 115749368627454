import {
  GET_FAQS_SUCCESS,
  GET_FAQS_FAIL,
  ADD_NEW_FAQ_SUCCESS,
  ADD_NEW_FAQ_FAIL,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAIL,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAIL
} from "./actionTypes";

const INIT_STATE = {
  faqs: [],
  error: {},
  loading: true
};

const FAQ = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_FAQS_SUCCESS:
      return {
        ...state,
        faqs: action.payload,
        loading: true
      };

    case GET_FAQS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_NEW_FAQ_SUCCESS:
      return {
        ...state,
        faqs: [...state.faqs, action.payload],
      };

    case ADD_NEW_FAQ_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        faqs: state.faqs.map(faq =>
          (faq._id + '') === (action.payload._id + '')
            ? { faq, ...action.payload }
            : faq
        ),
      };
    
    case UPDATE_FAQ_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_FAQ_SUCCESS:
      return {
        ...state,
        faqs: state.faqs.filter(
          faq => faq._id !== action?.payload?._id
        ),
      };

    case DELETE_FAQ_FAIL:
      return {
        ...state,
        error: action.payload
      };
    
    default:
      return state;
  }
};

export default FAQ;
