import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getMemberIDs } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    Card,
    CardBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { formatDate } from "helpers/date_helper";
import { Link } from "react-router-dom";

function IDsCount() {

    //meta title
    document.title = "Member ID's";
    const dispatch = useDispatch();
    const [pageNumber, setPage] = useState(1);
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const selectMemberState = (state) => state.Member;
    const MemberProperties = createSelector(
      selectMemberState,
      (Member) => ({
        memberIds: Member.memberIds,
        totalMemberIds: Member.totalMemberIds,
      })
    );
    const { memberIds, totalMemberIds } = useSelector(MemberProperties);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getMemberIDs(pageNumber, startDate, endDate));
    }, [dispatch, pageNumber, startDate, endDate]);
    

    const columns = useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'date',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {formatDate(cellProps?.row?.original?.createdAt)}
                    </>;
                }
            },
            {
                Header: 'Count',
                accessor: 'count',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.count}
                    </>;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                width: '10%',
                disableFilters: true,
                Cell: (cellProps) => {
                  return (
                    <div>
                        <Link to={`/member-ID's?date=${cellProps?.row?.original?.createdAt}`}
                            className=" btn btn-primary btn-sm"
                            style={{ color: "white" }}
                        >
                            View
                        </Link>
                    </div>
                  );
                }
              },
        ],
        []
    );
    return (
        <React.Fragment>
            
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Member ID's" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                        :
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={memberIds || []}
                                            isGlobalFilter={true}
                                            handleOrderClicks={() => { }}
                                            customPageSize={10}
                                            // isPagination={true}
                                            filterable={false}
                                            iscustomPageSizeOptions={true}
                                            tableClass="align-middle table-nowrap table-check"
                                            theadClass="table-light"
                                            pagination="pagination pagination-rounded justify-content-end mb-2"
                                            isCustomPagination={true}
                                            setPage={setPage}
                                            pageNumber={pageNumber}
                                            totals={totalMemberIds}
                                            isStartDate={true}
                                            startDate={startDate}
                                            setStartDate={setStartDate}
                                            isEndDate={true}
                                            endDate={endDate}
                                            setEndDate={setEndDate}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
IDsCount.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};


export default IDsCount;