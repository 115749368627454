import React from "react";
import propTypes from 'prop-types';

import {
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    Table
} from "reactstrap";
import { formatDate } from "helpers/date_helper";

function OffCanvasComponent(props) {
    return (
        <div>
            
            <Offcanvas
                direction="end"
                scrollable
                isOpen={props.isCanvasOpen}
                toggle={props.toggleCanvas}
                
            >
                <OffcanvasHeader
                    closeAriaLabel="Close"
                    className="d-flex justify-content-between"
                    toggle={props.toggleCanvas}
                >
                    Member Details
                </OffcanvasHeader>
                <OffcanvasBody>
{                // Member - fname, lname, mobile, upiId, isMobileVerified, isEmailVerified, createdAt, updatedAt 
}                
                <Table>
                    <tbody>
                        <tr>
                            <td>First Name</td>
                            <td>{props.selectedMember?.fname}</td>
                        </tr>
                        <tr>
                            <td>Last Name</td>
                            <td>{props.selectedMember?.lname}</td>
                        </tr>
                        <tr>
                            <td>Mobile</td>
                            <td>{props.selectedMember?.mobile}</td>
                        </tr>
                        <tr>
                            <td>UPI ID</td>
                            <td>{props.selectedMember?.upiId}</td>
                        </tr>
                        <tr>
                            <td>Mobile Verified</td>
                            <td>
                                {
                                    props.selectedMember?.isMobileVerified ?
                                    <span className="badge bg-success p-2">Verified</span> :
                                    <span className="badge bg-danger p-2">Not Verified</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Created At</td>
                            <td>{formatDate(props.selectedMember?.createdAt)}</td>
                        </tr>
                        <tr>
                            <td>Updated At</td>
                            <td>{formatDate(props.selectedMember?.updatedAt)}</td>
                        </tr>
                    </tbody>
                </Table>
                </OffcanvasBody>
            </Offcanvas>
        </div>
    );
}

OffCanvasComponent.propTypes = {
    selectedMember : propTypes.object,
    isCanvasOpen: propTypes.bool, 
    toggleCanvas: propTypes.func
};

export default OffCanvasComponent;

