import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty, set } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';


import {
  getTags as onGetTags,
} from "store/actions";
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { get, post, put } from "helpers/api_helper";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TableContainer from "components/Common/TableContainer";
import { formatDate } from "helpers/date_helper";
import AssignMembers from "./AssignMembers";
import CreateContainerModal from "./CreateContainerModal";
import { useFormik } from "formik";
import * as Yup from "yup"
import PublishContainerModal from "./PublishContainerModal";
import EditContainerNoteModal from "./EditContainerNoteModal";

const convertDateFormat = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};

function Members() {

  //meta title
  document.title = "Assign SH";
  const adminTokenID = process.env.REACT_APP_ADMIN_TOKEN_ID;
  const adminId = process.env.REACT_APP_ADMIN_ID;
  const [isLoading, setLoading] = useState(false);

  const [recipientDate, setRecipientDate] = useState("");
  const [senderDate, setSenderDate] = useState("");
  const dispatch = useDispatch();

  // const [recipient, setRecipient] = useState({});
  // const [senders, setSenders] = useState([]);
  const [data, setData] = useState({
    recipient: {},
    senders: [],
  });

  const [createNewContainerFromUrlBool, setCreateNewContainerFromUrlBool] = useState(new URLSearchParams(window.location.search).get("createNewContainer")==="true");

  const [dataSenders, setDataSenders] = useState([]);
  const [dataRecipient, setDataRecipient] = useState([]);

  const [assignToAdmin, setAssignToAdmin] = useState(false);

  const fetchSenders = async(date, id) => {
    setLoading(true);
    try {
      const response = await get("/members/sh/?date=" + date + "&&type=sender" + "&&tag=" + senderTag + "&&containerId=" + id);
      console.log("response", response);
      setDataSenders(response.data);
    }
    catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  }

  const fetchRecipient = async(date, id) => {
    setLoading(true);
    try {
      const response = await get("/members/sh/?date=" + date + "&&type=recipient" + "&&tag=" + receiverTag + "&&containerId=" + id);
      console.log("response", response);
      setDataRecipient(response.data);
    }
    catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  }

  const [senderTag, setSenderTag] = useState("All")
  const [receiverTag, setReceiverTag] = useState("All")

  const selectTagState = (state) => state.Tag;
  const TagProperties = createSelector(
      selectTagState,
      (Tag) => ({
          tags: Tag.tags
      })
  );

  const { tags } = useSelector(TagProperties);

  useEffect(() => {
    dispatch(onGetTags());
}, [dispatch]);

  // useEffect(() => {
  //   fetchSenders();
  // }, [senderTag]);

  // useEffect(() => {
  //   fetchRecipient();
  // }, [receiverTag]);
  

  const sendHelp = async (payload) => {
    try {
      const finalPayload = {
        ...payload,
        senders: data.senders.map((sender) => {
          return {
            _id: sender._id,
            memberId: sender?.memberId,
          }
        }),
      }
      console.log("finalPayload", finalPayload);
      const response = await post("/members/assign-send-help", finalPayload);
      const filteredData = [...dataSenders].filter((sender) => !data.senders.find((dataSender) => dataSender._id === sender._id));
      setDataSenders(filteredData);
      
      if(!payload.sendToAdmin)
        setDataRecipient([...dataRecipient].filter((dataRecipient) => dataRecipient._id !== data?.recipient?._id));

      setData({
        recipient: {},
        senders: [],
      });
      toast.success(`${response?.message || "SH Assigned Successfully"}`, { autoClose: 2000 });
    }
    catch (error) {
      console.log("error", error);
      toast.error("SH Assign Failed");
    }
    setLoading(false);
  }

  const sendHelpToMember = () => {
    if(isEmpty(data.recipient)){
      toast.error("Please select a recipient");
      return;
    }
    if(data.senders.length === 0){
      toast.error("Please select at least one sender");
      return;
    }
    const payload = {
      recipient: {
        memberId: data.recipient?.memberId,
        _id: data.recipient?._id,
      },
      sendToAdmin: false,
    }
      
    sendHelp(payload);
  }

  const sendHelpToAdmin = () => {
    const payload = {
      recipient: {
        memberId: adminId,
        _id: adminTokenID,
      },
      sendToAdmin: true,
    }
    if(data.senders.length === 0)
      toast.error("Please select at least one sender");
    else
      sendHelp(payload);
  }

  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [year, month, day].join('-');
  };

  const createDateTime = (date, time) => {
    const dateTimeString = `${date}T${time}:00`;
    const dateTime = new Date(dateTimeString);
    const offset = 5.5 * 60 * 60 * 1000; // +05:30 in milliseconds
    const adjustedDateTime = new Date(dateTime.getTime() + offset);
    return adjustedDateTime;
};

const addHours = (date, hours) => {
  return new Date(date.getTime() + hours * 60 * 60 * 1000);
};

const calculateEndDateTime = (publishDate, publishTime) => {
  const dateTimeString = `${publishDate}T${publishTime}:00`;
  const publishDateTime = new Date(dateTimeString);
  const endDateTime = addHours(publishDateTime, 9);
  const endDate = formatDate(endDateTime);
  const endTime = endDateTime.toTimeString().slice(0, 5);
  return { endDate, endTime };
};

  const [isEdit, setIsEdit] = useState(false)
  const [date, setDate] = useState(formatDate(new Date()))
  const [publishDate, setPublishDate] = useState(formatDate(new Date()))
  const [publishTime, setPublishTime] = useState("00:00");

  const initialEndDateTime = calculateEndDateTime(publishDate, publishTime);

  const [endDate, setEndDate] = useState(initialEndDateTime.endDate);
  const [endTime, setEndTime] = useState(initialEndDateTime.endTime);

  const handleDateChange = (e) => {
    setDate(e.target.value)
  }

  const handlePublishDateChange = (e) => { 
    const newPublishDate = e.target.value;
    setPublishDate(newPublishDate);
    const newEndDateTime = calculateEndDateTime(newPublishDate, publishTime);
    setEndDate(newEndDateTime.endDate);
    setEndTime(newEndDateTime.endTime);
  }

  const handleEndDateChange = (e) => { 
    setEndDate(e.target.value)
  }

  const handlePublishTimeChange = (e) => {
    const newPublishTime = e.target.value;
    setPublishTime(newPublishTime);
    const newEndDateTime = calculateEndDateTime(publishDate, newPublishTime);
    setEndDate(newEndDateTime.endDate);
    setEndTime(newEndDateTime.endTime);
  }

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value)
  }

  const [containers, setContainers] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [editNoteModal, setEditNoteModal] = useState(false);
  const [note, setNote] = useState("");
  const [createNewContainer, setCreateNewContainer] = useState(false);
  const [containerName, setContainerName] = useState("");
  const [containerNote, setContainerNote] = useState("");
  const [containerId, setContainerId] = useState("");
  const containerNameFromUrl = new URLSearchParams(window.location.search).get("containerName");
  const [isChangePublishDate, setIsChangePublishDate] = useState(false);

  const fetchContainers = async () => {
    try {
      const res = await get("/members/get-container");
      setContainers(res);
    } catch (error) {
      console.log(error)
    }
  }

  const handleCreateContainerClick = async () => {
    try {
      const res = await post("/members/create-container/?date=" + date + "&&type=sender" + "&&tag=" + senderTag , {
        date: convertDateFormat(date),
        note: note,
        isPublished: false,
      });
      if(res?.data) {
        setContainerName(res?.data?.containerName + " - " + res?.data?.note);
        setContainerId(res?.data?._id);
        // setRecipientDate(date);
        // setSenderDate(date);
        fetchSenders(date, res?.data?._id);
        fetchRecipient(date, res?.data?._id);
        toast.success("Container Created Successfully.");
      }
      setCreateModal(false);
      setCreateNewContainer(true);
      console.log(res)
    } catch (error) {
      console.log(error)
      toast.error("Container Failed to Create.");
    }
  }

  useEffect(() => {
    fetchContainers();
  }, [createNewContainer])

  const [publishError, setPublishError] = useState(false);

  const handlePublishClick = async () => {
    try {
      if (new Date(createDateTime(publishDate, publishTime)).getTime() + 9 * 60 * 60 * 1000 > new Date(createDateTime(endDate, endTime)).getTime()) {
        setPublishError(true);
        return;
      }
      const res = await post("/members/publish-container", {
        containerName: containerName,
        publishDateTime: createDateTime(publishDate, publishTime),
        endDateTime: createDateTime(endDate, endTime),
      });
      console.log(res)
      toast.success("Container Published Successfully.");
      setPublishModal(false);
      setPublishError(false);
    } catch (error) {
      console.log(error)
      toast.error("Container Failed to Publish.");
    }
  }

  const handleUpdateNoteClick = async () => {
    try {
      const res = await post("/members/edit-container-note", {
        containerName: containerName,
        note: note,
      });
      console.log(res)
      toast.success("Note Updated Successfully.");
      setEditNoteModal(false);
    } catch (error) {
      console.log(error)
      toast.error("Note Failed to Update.");
    }
  }
  const handleAssignSHClick = async (id, name, note) => {
    try {
      setCreateNewContainer(true);
      setContainerId(id);
      setContainerName(name);
      setContainerNote(note)
      fetchSenders(date, id);
      // fetchRecipient(date, id);
    } catch (error) {
      console.log(error)
    }
  }
      
  const columns = useMemo(
    () => [
        {
            Header: 'Container Name',
            accessor: 'containerName',
            width: '150px',
            style: {
                textAlign: "center",
                width: "10%",
                background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
                return <>
                    {cellProps?.row?.original?.containerName}
                </>;
            }
        },
        {
            Header: 'Note',
            accessor: 'note',
            width: '150px',
            style: {
                textAlign: "center",
                width: "10%",
                background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
                return <>
                    {cellProps?.row?.original?.note}
                </>;
            }
        },
        {
            Header: 'Date',
            accessor: 'date',
            width: '150px',
            style: {
                textAlign: "center",
                width: "10%",
                background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
                const date = formatDate(cellProps?.row?.original?.createdAt)
                const isHighlighted = date === formatDate(new Date());
                return <>
                        {date}
                </>;
            }
        },
        {
            Header: 'Published',
            accessor: 'published',
            width: '150px',
            style: {
                textAlign: "center",
                width: "10%",
                background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
                return <>
                    {cellProps?.row?.original?.isPublished ? 
                        <span className="badge bg-success p-2">Yes</span> : 
                        <span className="badge bg-danger p-2">No</span>
                    }
                </>;
            }
        },
        {
            Header: 'Action',
            accessor: 'action',
            disableFilters: true,
            width: "150px",
            style: {
                textAlign: "center",
                width: "10%",
                background: "#0000",
            },
            Cell: (cellProps) => {
                return (
                    <div className="d-flex gap-2">
                        <Link
                          to="#"
                          className="text-success"
                          onClick={() => {
                            setEditNoteModal(true)
                            setContainerName(cellProps.row.original.containerName)
                            setNote(cellProps.row.original.note)
                          }}
                        >
                          <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                          <UncontrolledTooltip placement="top" target="edittooltip">
                            Edit Note
                          </UncontrolledTooltip>
                        </Link>
                        <Button
                            type="button"
                            color={cellProps?.row?.original?.isPublished ? "success" : "primary"}
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              setPublishModal(true)
                              setContainerName(cellProps.row.original.containerName)
                              if(cellProps?.row?.original?.isPublished) {
                                setIsChangePublishDate(true);
                                setPublishDate(formatDate(cellProps?.row?.original?.publishDateTime));
                                setPublishTime(cellProps?.row?.original?.publishDateTime?.split("T")[1].slice(0, 5));
                                setEndDate(formatDate(cellProps?.row?.original?.endDateTime));
                                setEndTime(cellProps?.row?.original?.endDateTime?.split("T")[1].slice(0, 5));
                              } else {
                                setIsChangePublishDate(false);
                                setPublishDate(formatDate(new Date()));
                                setPublishTime("00:00");
                                setEndDate(formatDate(new Date()));
                                setEndTime("00:00");
                              }
                            }}
                            // onClick={() => handlePublishClick(cellProps.row.original)}
                        >
                            {cellProps?.row?.original?.isPublished ? "Change Publish Date" : "Publish"}
                        </Button>
                        <Button
                            type="button"
                            color="primary"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              handleAssignSHClick(cellProps.row.original._id, cellProps.row.original.containerName, cellProps.row.original.note)
                            }}
                        >
                            Assign SH
                        </Button>
                        <Link
                          to={`/container-members?containerId=${cellProps.row.original._id}&containerName=${cellProps.row.original.containerName}`}
                          className="text-success"
                          onClick={() => {
                            setContainerId(cellProps.row.original._id)
                            setContainerName(cellProps.row.original.containerName)
                          }}
                        >
                          <Button 
                            type="button"
                            color="primary"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              // setContainerId(cellProps.row.original._id)
                            }}
                          >
                            View Assignments
                          </Button>
                        </Link>
                        {/* <Link
                            to="#"
                            className="text-danger"
                            onClick={() => {
                                const day = cellProps.row.original;
                                onClickDelete(day);
                            }}
                        >
                            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                Delete
                            </UncontrolledTooltip>
                        </Link> */}
                    </div>
                );
            }
        },
    ],
    []
);

  return (
    <React.Fragment>
      <CreateContainerModal
        show={createModal}
        onCreateClick={handleCreateContainerClick}
        onCloseClick={() => setCreateModal(false)}
        isEdit={isEdit}
        date={date}
        setDate={handleDateChange}
        title="Create Container"
      />
      <PublishContainerModal
        show={publishModal}
        onPublishClick={handlePublishClick}
        onCloseClick={() => {setPublishModal(false); setPublishError(false)}}
        isEdit={isEdit}
        publishDate={publishDate}
        setPublishDate={handlePublishDateChange}
        endDate={endDate}
        setEndDate={handleEndDateChange}
        publishTime={publishTime}
        setPublishTime={handlePublishTimeChange}
        endTime={endTime}
        setEndTime={handleEndTimeChange}
        containerName={containerName}
        publishError={publishError}
        setPublishError={setPublishError}
        isChangePublishDate={isChangePublishDate}
        // setIsChangePublishDate={setIsChangePublishDate}
        title="Publish Container"
      />
      <EditContainerNoteModal
        show={editNoteModal}
        onUpdateClick={handleUpdateNoteClick}
        onCloseClick={() => setEditNoteModal(false)}
        isEdit={isEdit}
        note={note}
        setNote={(e) => setNote(e.target.value)}
        containerName={containerName}
       />
        
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem={(createNewContainer  || createNewContainerFromUrlBool) ? (
            <>
              Assign SH / Container / {containerName || containerNameFromUrl}
              {containerNote && <span className="text-danger"> - {containerNote}</span>}
            </>
          ) 
          : "Assign SH / Container"}  />
          {
            isLoading ? <Spinners/>
              :
              <>
                {
                  (createNewContainer || createNewContainerFromUrlBool) ? 
                  <AssignMembers
                    sendersData={dataSenders}
                    recipientData={dataRecipient}
                    containerId={containerId}
                    setCreateNewContainer={setCreateNewContainer}
                    createNewContainerFromUrlBool={createNewContainerFromUrlBool}
                    setCreateNewContainerFromUrlBool={setCreateNewContainerFromUrlBool}
                    setContainerId={setContainerId}
                    containerName={containerName}
                   /> :
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <TableContainer
                            columns={columns}
                            data={containers || []} 
                            isGlobalFilter={true}
                            // isAddOptions={true}
                            customPageSize={11}
                            isPagination={true}
                            filterable={false}
                            iscustomPageSizeOptions={true}
                            tableClass="align-middle table-nowrap table-check"
                            theadClass="table-light"
                            pagination="pagination pagination-rounded justify-content-end mb-2"
                            createContainer={true}
                            handleCreateContainerClick={() => setCreateModal(true)}
                            handlePublishClick={() => setPublishModal(true)}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                }
              </>
          }
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

Members.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default Members;