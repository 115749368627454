// Tag action types
export const GET_TAGS = "GET_TAGS"
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS"
export const GET_TAGS_FAIL = "GET_TAGS_FAIL"

export const ADD_NEW_TAG = "ADD_NEW_TAG"
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS"
export const ADD_TAG_FAIL = "ADD_TAG_FAIL"

export const UPDATE_TAG = "UPDATE_TAG"
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS"
export const UPDATE_TAG_FAIL = "UPDATE_TAG_FAIL"

export const DELETE_TAG = "DELETE_TAG"
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS"
export const DELETE_TAG_FAIL = "DELETE_TAG_FAIL"

// Day action types
export const GET_DAYS = "GET_DAYS"
export const GET_DAYS_SUCCESS = "GET_DAYS_SUCCESS"
export const GET_DAYS_FAIL = "GET_DAYS_FAIL"

export const ADD_NEW_DAY = "ADD_NEW_DAY"
export const ADD_NEW_DAY_SUCCESS = "ADD_NEW_DAY_SUCCESS"
export const ADD_NEW_DAY_FAIL = "ADD_NEW_DAY_FAIL"

export const UPDATE_DAY = "UPDATE_DAY"
export const UPDATE_DAY_SUCCESS = "UPDATE_DAY_SUCCESS"
export const UPDATE_DAY_FAIL = "UPDATE_DAY_FAIL"

export const DELETE_DAY = "DELETE_DAY"
export const DELETE_DAY_SUCCESS = "DELETE_DAY_SUCCESS"
export const DELETE_DAY_FAIL = "DELETE_DAY_FAIL"