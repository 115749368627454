const SH_GH_TYPES = {
    ALL: 'ALL',
    COMPLETED: 'COMPLETED',
    APPROVAL_PENDING: 'APPROVAL_PENDING',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
};

const VIDEO_KYC_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED'
}

const MEMBER_STAGE = {
    NEW: 'NEW',
    SLIVER: 'SLIVER',
    GOLD: 'GOLD',
    DIAMOND: 'DIAMOND',
    PLATINUM: 'PLATINUM',
}

// export
export {
    SH_GH_TYPES,
    VIDEO_KYC_STATUS,
    MEMBER_STAGE
}