import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import {
  changeOrderStatus,
  getPickUpPoints,
  getUpcomingOrders as onGetUpcoming,
  cancelOrder as cancelOrderAction
} from "store/actions";


//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import OffCanvasComponent from "pages/Customers/OffCanvasComponent";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { formatDate } from "helpers/date_helper";
import moment from "moment";
import CancelOrderModal from "components/Common/CancelOrderModal";
import { CANCEL_ORDER_API } from "helpers/url_helper";
import { post } from "helpers/api_helper";

function Upcoming() {

  //meta title
  document.title = "Master >> Upcoming";
  const dispatch = useDispatch();

  const selectOrderState = (state) => state.Order;
  const OrderProperties = createSelector(
    selectOrderState,
    (Order) => ({
      upcomingOrders: Order.upcomingOrders,
      totalOrders: Order.totalOrders
    })
  );
  const { upcomingOrders, totalOrders } = useSelector(OrderProperties);

  const selectPickUpPointState = (state) => state.PickupPoint;
  const PickupPointProperties = createSelector(
      selectPickUpPointState,
      (PickupPoint) => ({
          pickUpPoints: PickupPoint.pickUpPoints
      })
  );
  const { pickUpPoints } = useSelector(PickupPointProperties);

  const [isLoading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [pageNumber, setPage] = useState(1);

  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(() => {
    const start = new Date(startDate);
    const end = new Date(start.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]; // 7 days in milliseconds
    return end;
  });

  useEffect(() => {
    dispatch(onGetUpcoming(pageNumber, startDate, endDate));
    dispatch(getPickUpPoints());
  }, [dispatch,startDate, endDate, pageNumber]);

  
  const [pickUpType, setPickUpType] = useState("All");
  const [pickUpPoint,setpickUpPoint] = useState("All");

  useEffect(() => {
    setOrders(filterOrders(upcomingOrders, pickUpPoint, pickUpType));
  },[pickUpType, pickUpPoint, startDate, endDate])

  const [isCanvasOpen, setIsCanvasOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const toggleCanvas = () => {
      setIsCanvasOpen(!isCanvasOpen);
  };

  const onOrderClick= (orderDetails) => {
      toggleCanvas();
      setSelectedOrder(orderDetails);
  }

  const csvData = upcomingOrders.map((order) => {
    return {
      orderId: order?.orderId,
      customerName: order?.customer?.fname + " " + order?.customer?.lname,
      mobile: order?.customer?.mobile,
      toy_pic: order?.toy?.defaultPhoto,
      toy_name: order?.toy?.name,
      city: order?.customer?.city?.name,
      zone: order?.customer?.zone?.name,
      pincode: order?.customer?.pincode,
      orderDate: formatDate(order?.createdAt),
      deliveryDate: formatDate(order?.deliveryDate),
      returnDate: formatDate(order?.returnDate),
      orderTotal: order?.orderTotal,
      deliverCharge: order?.deliveryFee,
      duration: moment(order?.returnDate).diff(order?.deliveryDate, 'days') + " days",
      paid: order?.paid,
      selfPickup: order?.selfPickup ? "Yes" : "No",
    };
  });

  const csvConfig = mkConfig({ useKeysAsHeaders: true });

  const exportToCSVButtonClicked = () => {
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv)  
  }

  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [reason, setReason] = useState("");
  const [orderId, setOrderId] = useState("");

  const onClickCancelOrder = async (id) => {
    setCancelOrderModal(true);
    setOrderId(id);
    console.log(orderId);
  } 

  const cancelOrder = async () => {
    try {
      if(reason === "") {
        toast.error("Please enter reason to cancel order");
        return;
      }
        const res = await post(CANCEL_ORDER_API, {orderId, reason});
        toast.success("Order Cancelled Successfully");
        dispatch(onGetUpcoming(pageNumber, startDate, endDate));
        setReason("");
        setCancelOrderModal(false);
    } catch (error) {
        let errorMessage = error?.response?.data?.message || "Something went wrong";
        toast.error(errorMessage);
    }
  }

  const [orderChecked, setOrderChecked] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [orderStatus, setOrderStatus] = useState("pending");
  const columns = useMemo(
    () => [
      {
        Header: 'Order ID',
        accessor: "orderId",
        width: "150px",
        style: {
            textAlign: "center",
            width: "10%",
            background: "#0000",
        },
        filterable: true,
        Cell: ({ row }) => {
            return <>{row?.original?.orderId ?? " "} </>;
        },
      },
      {
        Header: 'Customer Name',
        accessor: 'name',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.customer?.fname + " " + cellProps?.row?.original?.customer?.lname}
          </>;
        }
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.customer?.mobile}
          </>;
        }
      },
      {
        Header: 'Toy Pic',
        accessor: 'toy_pic',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            <img src={cellProps?.row?.original?.toy?.defaultPhoto} alt="toyImage" className="avatar-sm"/>          </>;
        }
      },
      {
        Header: 'Toy Name',
        accessor: 'toy_name',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.toy?.name}
          </>;
        }
      },
      {
        Header: 'City',
        accessor: 'city',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.customer?.city?.name}
          </>;
        }
      },
      {
        Header: 'Zone',
        accessor: 'zone',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.customer?.zone?.name}
          </>;
        }
      },
      {
        Header: 'Pin Code',
        accessor: 'pincode',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {cellProps?.row?.original?.customer?.pincode}
          </>;
        }
      },
      {
        Header: 'Delivery Date',
        accessor: 'deliveryDate',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {formatDate(cellProps?.row?.original?.deliveryDate)}
          </>;
        }
      },
      {
        Header: 'Return Date',
        accessor: 'returnDate',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {formatDate(cellProps?.row?.original?.returnDate)}
          </>;
        }
      },
      {
        Header: "Action",
        accessor: "action",
        width: "150px",
        style: {
            textAlign: "center",
            width: "10%",
            background: "#0000",
        },
        filterable: true,
        Cell: ({ row }) => {
            
            return (
                <Button
                    color="primary"
                    onClick={() => onOrderClick(row?.original)}
                >
                    View
                </Button>
            );
        }
      },
      {
        // Column for the cancel order button
        Header: "Cancel Order",
        accessor: "cancelOrder",
        width: "150px",
        style: {
            textAlign: "center",
            width: "10%",
            background: "#0000",
        },
        filterable: true,
        Cell: ({ row }) => {
            return (
                <Button
                    color="danger"
                    onClick={() =>  onClickCancelOrder(row?.original?._id)}
                >
                    Cancel
                </Button>
            );
        }
      }
    ],
    []
  );
  
  return (
    <React.Fragment>

    <CancelOrderModal
      show={cancelOrderModal}
      onCancelClick={() => cancelOrder()}
      onCloseClick={() => setCancelOrderModal(false)}
      setReason={setReason}
    />

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Upcoming-Orders" />
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>

                      <TableContainer
                        title="Age Group"
                        columns={columns}
                        data={pickUpType === "All" ? upcomingOrders : orders}
                        isGlobalFilter={true}
                        isStartDate={true}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        isEndDate={true}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        handleOrderClicks={() => { }}
                        customPageSize={10}
                        isPagination={false}
                        filterable={false}
                        csvExport={true}
                        exportToCSVButtonClicked={exportToCSVButtonClicked}
                        isPickUpPointOptions={true}
                        pickUpType={pickUpType}
                        setPickUpType={setPickUpType}
                        pickUpPoints={pickUpPoints}
                        setpickUpPoint={setpickUpPoint}
                        pickUpPoint={pickUpPoint}
                        iscustomPageSizeOptions={true}
                        setAllChecked={setAllChecked}
                        allChecked={allChecked}
                        setOrderChecked={setOrderChecked}
                        orderChecked={orderChecked}
                        isCheckable={true}
                        setOrderStatus={setOrderStatus}
                        orderStatus={orderStatus}
                        tableClass="align-middle table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        isCustomPagination={true}
                        setPage={setPage}
                        pageNumber={pageNumber}
                        totals={totalOrders}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
          {
            <Modal size="lg" isOpen={orderStatus !== "pending"} toggle={ () => setOrderStatus("pending")}>
            <ModalHeader toggle={ () => setOrderStatus("pending")} tag="h4">
              {
                orderStatus === "delivered" ? 
                "Are you sure you want to mark the selected orders as delivered?" :
                "Are you sure you want to mark the selected orders as returned?"
              }
            </ModalHeader>
            <ModalBody>
                <div className="">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        onClick={() => changeOrderStatus(orderChecked, orderStatus)}
                      >
                      {
                        orderStatus === "delivered" ? 
                        "Mark as Delivered" : "Mark as Returned"
                      }
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary ms-2"
                        onClick={() => setOrderStatus("pending")}
                      >
                        Close
                      </button>
                    </div>
            </ModalBody>
          </Modal>
          }
        </div>
      </div>
      <ToastContainer />
      <OffCanvasComponent
        isCanvasOpen={isCanvasOpen}
        toggleCanvas={toggleCanvas}
        selectedOrder={selectedOrder} 
      />
    </React.Fragment>
  );
}
Upcoming.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default Upcoming;


export const filterOrders = (ordersList, pickUpPoint, pickUpType) => {
  let orders = [];
  if (pickUpType == "All") {
    return ordersList;
  }
  else if (pickUpType == "Company") {
    orders = ordersList.filter(order => {
      return order?.selfPickup === null || order?.selfPickup === undefined;
    });
  }
  else if (pickUpType == "Self" && pickUpPoint === "All") {
    orders = ordersList.filter(order => {
      return order?.selfPickup;
    });
  }
  else if (pickUpType == "Self" && pickUpPoint !== "All") {
    orders = ordersList.filter(order => {
      return order?.selfPickup && order?.selfPickup?.storeName === pickUpPoint;
    });
  }
  return orders;
}