import { call, put, takeEvery } from "redux-saga/effects";

// City Redux States
import {
  GET_TAGS,
  ADD_NEW_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  GET_DAYS,
  ADD_NEW_DAY,
  UPDATE_DAY,
  DELETE_DAY
} from "./actionTypes";
import {
  getTagsSuccess,
  getTagsFail,
  addTagFail,
  addTagSuccess,
  updateTagSuccess,
  updateTagFail,
  deleteTagSuccess,
  deleteTagFail,
  getDaysSuccess,
  addDayFail,
  getDaysFail,
  addDaySuccess,
  updateDaySuccess,
  updateDayFail,
  deleteDaySuccess,
  deleteDayFail
} from "./actions";
import {
  getTags,
  addNewTag,
  updateTag,
  deleteTag,
  getDays,
  addNewDay,
  updateDay,
  deleteDay
} from "helpers/tags_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// Tag Table
function* fetchTags({payload: filter}) { 
  try {
    const response = yield call(getTags, filter);
    console.log(response);
    yield put(getTagsSuccess(response));
  } catch (error) {
    yield put(getTagsFail(error));
  }
}

function* onUpdateTag({ payload: category }) {
  try {
    const response = yield call(updateTag, category);
    yield put(updateTagSuccess(response));
    toast.success("Tag Update Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateTagFail(error));
    let msg = error?.response?.data?.msg || "Tag Update Failed"; 
    toast.error(msg, { autoClose: 2000 });
  }
}

function* onDeleteTag({ payload: category }) {
  try {
    const response = yield call(deleteTag, category);
    yield put(deleteTagSuccess(response));
    toast.success("Tag Delete Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteTagFail(error));
    toast.error("Tag Delete Failed", { autoClose: 2000 });
  }
}

function* onAddNewTag({ payload: category }) {
  try {
    const response = yield call(addNewTag, category);
    yield put(addTagSuccess(response));
    toast.success("Tag Added Successfully", { autoClose: 2000 });
  } catch (error) {
    let msg = error?.response?.data?.msg || "Tag Added Failed"; 
    toast.error(msg, { autoClose: 2000 });

    yield put(addTagFail(error));
  }
}

// Day Table
function* fetchDays() {
  try {
    const response = yield call(getDays);
    console.log(response)
    yield put(getDaysSuccess(response));
  } catch (error) {
    console.log(error)
    yield put(getDaysFail(error));
  }
}

function* onUpdateDay({ payload: day }) {
  try {
    const response = yield call(updateDay, day);
    yield put(updateDaySuccess(response));
    toast.success("Day Update Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateDayFail(error));
    toast.error("Day Update Failed", { autoClose: 2000 });
  }
}

function* onDeleteDay({ payload: day }) {
  try {
    const response = yield call(deleteDay, day);
    yield put(deleteDaySuccess(response));
    toast.success("Day Delete Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteDayFail(error));
    toast.error("Day Delete Failed", { autoClose: 2000 });
  }
}

function* onAddNewDay({ payload: day }) {
  try {
    const response = yield call(addNewDay, day);
    yield put(addDaySuccess(response));
    toast.success("Day Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addDayFail(error));
    toast.error(`${error?.response?.data?.message || "Day Added Failed"}`, { autoClose: 2000 });
  }
}


function* TagSaga() {
  // Tag Table
  yield takeEvery(GET_TAGS, fetchTags);
  yield takeEvery(ADD_NEW_TAG, onAddNewTag);
  yield takeEvery(UPDATE_TAG, onUpdateTag);
  yield takeEvery(DELETE_TAG, onDeleteTag);
  // Day Table
  yield takeEvery(GET_DAYS, fetchDays);
  yield takeEvery(ADD_NEW_DAY, onAddNewDay);
  yield takeEvery(UPDATE_DAY, onUpdateDay);
  yield takeEvery(DELETE_DAY, onDeleteDay);
}

export default TagSaga;
