import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getMembers, getCustomers as onGetCustomers } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import moment from "moment";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";

import {
    getZones,
    getCities,
    deleteCustomer,
    getCustomerEnquiries
} from "store/actions";
import DeleteModal from "components/Common/DeleteModal";
import { post, put } from "helpers/api_helper";
import { formatDate } from "helpers/date_helper";
import VideoKYCModal from "./VideoKYCModal";
import { VIDEO_KYC_STATUS } from "../../common/data/types";

function VideoKYC() {

    //meta title
    document.title = "Video Consent";

    const dispatch = useDispatch();
    const [pageNumber, setPage] = useState(1);

    const selectMemberState = (state) => state.Member;
    const MemberProperties = createSelector(
      selectMemberState,
      (Member) => ({
        members: Member.members,
        totalMembers: Member.totalMembers,
      })
    );
    const { members, totalMembers } = useSelector(MemberProperties);
    console.log("members", members);

    const[videoStatus, setVideoStatus] = useState("");
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        console.log("useEffect");
        console.log("pageNumber", pageNumber);
        dispatch(getMembers(pageNumber, "videoKYC"));
    }, [dispatch,pageNumber]);
    

    const [videoKYCModal, setVideoKYCModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState("");
    const handleMemberClick = (customerData) => {
        setVideoKYCModal(true);
        setSelectedMember(customerData);
    }

    const changeStatus = async (status) => {
        try {
            const response = await post(`members/change-status`, { Id: selectedMember, Status: status, Type: "videoKYC"});
            toast.success("Status updated successfully");
            dispatch(getMembers(pageNumber, "videoKYC"));
        } catch (error) {
            console.log("error", error);
            toast.error("Error in updating status");
        }
    }

    const onApprove = async () => {
        await changeStatus("APPROVED");
        setVideoKYCModal(false);
    }

    const onReject = async () => {
        await changeStatus("REJECTED");
        setVideoKYCModal(false);
    }

    const filterMembers = (members, status) => {
        if(status === "" || status === "All") {
            return members;
        }
        return members.filter(member => member.videoKYC?.status === status);
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'fname',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.fname + " " + cellProps?.row?.original?.lname}
                    </>;
                }
            },
            {
                Header: 'Mobile',
                accessor: 'mobile',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.mobile}
                    </>;
                }
            },
            {
                Header: 'Registered On',
                accessor: 'registeredOn',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {formatDate(cellProps?.row?.original?.createdAt)}
                    </>;
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        <span className={`badge p-1 ${cellProps?.row?.original?.videoKYC?.status === VIDEO_KYC_STATUS.PENDING ? "bg-warning" : "bg-danger" }`}>{cellProps?.row?.original?.videoKYC?.status}</span>
                    </>;
                }
            },
            // {
            //     Header: 'Referred By',
            //     accessor: 'referredBy',
            //     width: '150px',
            //     style: {
            //         textAlign: "center",
            //         width: "10%",
            //         background: "#0000",
            //     },
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <>
            //             {(cellProps?.row?.original?.referredBy?.fname + " " + cellProps?.row?.original?.referredBy?.lname) || "-"}
            //         </>;
            //     }
            // },
            {
                Header: 'Action',
                accessor: 'action',
                width: '10%',
                disableFilters: true,
                Cell: (cellProps) => {
                  return (
                    <div>
                      <Button
                        color="primary"
                        onClick={
                          () => handleMemberClick(cellProps?.row?.original)
                        }
                      >
                        View
                      </Button>
                    </div>
                  );
                }
              },
        ],
        []
    );
    const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
    return (
        <React.Fragment>
            <VideoKYCModal
                show={videoKYCModal}
                onApprove={onApprove}
                onReject={onReject}
                onCloseClick={() => setVideoKYCModal(false)}
                title={"Video Consent"}
                imageSrc={
                    selectedMember?.profileImage?.includes("s3.amazonaws.com")
                    ? selectedMember?.profileImage : imageBaseUrl + selectedMember?.profileImage?.replace("uploads", "upload")
                }
                videoSrc={selectedMember?.videoKYC?.url?.includes("s3.amazonaws.com") 
                ? selectedMember?.videoKYC?.url : (imageBaseUrl + selectedMember?.videoKYC?.url?.replace("uploads", "upload"))}
                status={selectedMember?.videoKYC?.status}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Video Consent" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>

                                            <TableContainer
                                                columns={columns}
                                                data={videoStatus === "All" ? members || [] : filterMembers(members, videoStatus)}
                                                isGlobalFilter={true}
                                                handleOrderClicks={() => { }}
                                                customPageSize={10}
                                                // isPagination={true}
                                                filterable={false}
                                                iscustomPageSizeOptions={true}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-end mb-2"
                                                isCustomPagination={true}
                                                setPage={setPage}
                                                pageNumber={pageNumber}
                                                totals={totalMembers}
                                                isVideoStatusFilter={true}
                                                videoStatus={videoStatus}
                                                setVideoStatus={setVideoStatus}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
VideoKYC.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};


export default VideoKYC;