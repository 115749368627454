import PropTypes from 'prop-types'
import React from "react"
import { Modal, ModalBody } from "reactstrap"

const VideoKYCModal = ({ show, onCloseClick, onSendClick, qty, name , mobile, src, sent }) => {
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <h5 className="mb-4">
            {
              sent !== "SENT" ? 
              <>Are you sure you want to send <strong>{qty}</strong> pins <br /> to <strong>{name} ({mobile})</strong> ?</>
              :
              <><strong>{qty}</strong> Pins sent to <strong>{name} ({mobile})</strong></>
            }
          </h5>
          <img src={src} alt="screenShot" className=" p-2" width={"400px"} height={"600px"}
              style={{
                border: '2px solid #e5e5e5', 
                borderRadius: '15px',
              }}
            />
          <div className="hstack gap-2 justify-content-center mb-0 mt-4">
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Close</button>
            {
              sent !== "SENT" && <button type="button" className="btn btn-success" onClick={onSendClick}>Send</button>
            }
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

VideoKYCModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSendClick: PropTypes.func.isRequired,
  qty: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
}

export default VideoKYCModal
