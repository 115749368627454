import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getMembers, getPinEnquiries as getPinEnquiries } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Button,
    Col,
    Row,
    Card,
    CardBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";

import { post } from "helpers/api_helper";
import { formatDate } from "helpers/date_helper";
import PinSendModal from "./PinSendModal";

function FreePinRequest() {

    //meta title
    document.title = "Free PIN Request";

    const dispatch = useDispatch();
    const [pageNumber, setPage] = useState(1);

    const selectMemberState = (state) => state.Member;
    const MemberProperties = createSelector(
      selectMemberState,
      (Member) => ({
        members: Member.members,
        totalMembers: Member.totalMembers,
      })
    );
    const { members, totalMembers } = useSelector(MemberProperties);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getMembers(pageNumber, "freePinRequest"));
    }, [dispatch,pageNumber]);
    
    console.log("members", members);

    const [pinSendModal, setPinSendModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState("");
    const handleMemberClick = (customerData) => {
        setPinSendModal(true);
        setSelectedMember(customerData);
    }

    const sendPins = async () => {
        try {
            const response = await post(`members/unlock-freepin`, { 
                memberId: selectedMember?._id,
            });
            toast.success("Pins sent successfully");
            setPinSendModal(false);
            dispatch(getMembers(pageNumber, "freePinRequest"));
        } catch (error) {
            console.log("error", error);
            toast.error("Failed to send pins");
        }
    }

    const rejectPins = async () => {
        try {
            const response = await post(`members/reject-freepin`, {
                memberId: selectedMember?._id,
            });
            toast.success("Pins rejected successfully");
            setPinSendModal(false);
            dispatch(getMembers(pageNumber, "freePinRequest"));
        } catch (error) {
            console.log("error", error);
            toast.error("Failed to reject pins");
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'fname',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    console.log("cellProps", cellProps?.row?.original?.fname);
                    return <>
                        {cellProps?.row?.original?.fname + " " + cellProps?.row?.original?.lname}
                    </>;
                }
            },
            {
                Header: 'Mobile',
                accessor: 'mobile',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.mobile}
                    </>;
                }
            },
            // {
            //     Header: 'Requested On',
            //     accessor: 'requestedOn',
            //     width: '150px',
            //     style: {
            //         textAlign: "center",
            //         width: "10%",
            //         background: "#0000",
            //     },
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <>
            //             {formatDate(cellProps?.row?.original?.createdAt)}
            //         </>;
            //     }
            // },
            {
                Header: 'Status',
                accessor: 'status',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.freePinStatus === "APPROVED" ? 
                        <span className="badge bg-success p-2">{cellProps?.row?.original?.freePinStatus}</span> :
                        <span className={cellProps?.row?.original?.freePinStatus === "REQUESTED" ? "badge bg-warning p-2" : "badge bg-danger p-2"}>{cellProps?.row?.original?.freePinStatus}</span>}
                    </>;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                width: '10%',
                disableFilters: true,
                Cell: (cellProps) => {
                  return (
                    <div>
                      <Button
                        color="primary"
                        onClick={
                          () => handleMemberClick(cellProps?.row?.original)
                        }
                      >
                        View
                      </Button>
                    </div>
                  );
                }
              },
        ],
        []
    );
    const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
    console.log(selectedMember)
    return (
        <React.Fragment>
            <PinSendModal
                show={pinSendModal}
                onCloseClick={() => setPinSendModal(false)}
                qty={selectedMember?.qty}
                name={selectedMember?.fname + " " + selectedMember?.lname}
                mobile={selectedMember?.mobile}
                src={
                    selectedMember?.freePinImage?.includes("s3.amazonaws.com") ?
                    selectedMember?.freePinImage : imageBaseUrl + selectedMember?.freePinImage?.replace("uploads", "upload")
                }
                sent={selectedMember?.status}
                onSendClick={sendPins}
                onRejectClick={rejectPins}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Free PIN Requests" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={members || []}
                                            isGlobalFilter={true}
                                            handleOrderClicks={() => { }}
                                            customPageSize={10}
                                            // isPagination={true}
                                            filterable={false}
                                            iscustomPageSizeOptions={true}
                                            tableClass="align-middle table-nowrap table-check"
                                            theadClass="table-light"
                                            pagination="pagination pagination-rounded justify-content-end mb-2"
                                            isCustomPagination={true}
                                            setPage={setPage}
                                            pageNumber={pageNumber}
                                            totals={totalMembers}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
FreePinRequest.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};


export default FreePinRequest;