import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import {
    getTags as onGetTags,
    updateTag as onUpdateTag,
    addNewTag as onAddNewTag,
    deleteTag as onDeleteTag
} from "store/actions";


//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

function Tags() {

    //meta title
    document.title = "Master >> Tags";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [tag, setTag] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            status: (tag && tag.status) || '',
            name: (tag && tag.name) || '',
        },

        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Tag Name"),
            status: Yup.string().required("Please Select Status")
        }),

        onSubmit: (values) => {
            if (isEdit) {
                const updateTag = {
                    _id: tag ? tag._id : '',
                    status: values.status == 'true' ? true : false,
                    name: values.name,
                };
                dispatch(onUpdateTag(updateTag));
                validation.resetForm();
            } else {

                const newTag = {
                    status: values["status"] == "true" ? true : false,
                    name: values["name"],
                };
                dispatch(onAddNewTag(newTag));
                validation.resetForm();
            }
            toggle();
        },
    });


    const dispatch = useDispatch();

    const selectCityState = (state) => state.City;
    const CityProperties = createSelector(
        selectCityState,
        (City) => ({
            cities: City.cities
        })
    );

    const { cities } = useSelector(CityProperties);

    const selectTagState = (state) => state.Tag;
    const TagProperties = createSelector(
        selectTagState,
        (Tag) => ({
            tags: Tag.tags
        })
    );

    const { tags } = useSelector(TagProperties);
    console.log(tags);

    useEffect(() => {
        dispatch(onGetTags());
    }, [dispatch]);

    useEffect(() => {
        if (!isEmpty(cities) && !!isEdit) {
            setIsEdit(false);
        }
    }, [cities]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setTag(null);
        } else {
            setModal(true);
        }
    };


    const handleTagClick = arg => {
        const tag = arg;
        setTag({
            _id: tag._id,
            status: '' + tag.status,
            name: tag.name,
        });

        setIsEdit(true);

        toggle();
    };

    const onClickDelete = (tag) => {
        setTag(tag);
        setDeleteModal(true);
    };

    const handleTagDelete = () => {
        if (tag && tag._id) {
            dispatch(onDeleteTag(tag._id));
            setDeleteModal(false);
        }
    };

    const handleAddTagClick = () => {
        setIsEdit(false);
        setTag(null);
        toggle();
    };


    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.name}
                    </>;
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {cellProps?.row?.original?.status ? 'Active' : 'Inactive'}
                    </>;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const tagData = cellProps.row.original;
                                    handleTagClick(tagData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const tagData = cellProps.row.original;
                                    onClickDelete(tagData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleTagDelete}
                onCloseClick={() => setDeleteModal(false)}
                title="Tag"
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Masters" breadcrumbItem="Tags" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>

                                            <TableContainer
                                                title={"Tag"}
                                                columns={columns}
                                                data={tags || []}
                                                isGlobalFilter={true}
                                                isAddOptions={true}
                                                handleOrderClicks={handleAddTagClick}
                                                customPageSize={10}
                                                isPagination={true}
                                                filterable={false}
                                                iscustomPageSizeOptions={true}
                                                tableClass="align-middle table-nowrap table-check"
                                                theadClass="table-light"
                                                pagination="pagination pagination-rounded justify-content-end mb-2"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit Tag" : "Add Tag"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">



                                        <div className="mb-3">
                                            <Label>Tag Name</Label>
                                            <Input
                                                name="name"
                                                type="text"
                                                placeholder="Tag Name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.name || ''}
                                                invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>



                                        <div className="mb-3">
                                            <Label>Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                className="form-select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.status}

                                            >
                                                <option>Select Status</option>
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </Input>
                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback type="invalid" className="d-block">{validation.errors.status}</FormFeedback>
                                            ) : null}
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
Tags.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default Tags;