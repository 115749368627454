import {
  GET_PAYMENT_HISTORY,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_PAYMENT_HISTORY_FAIL
} from "./actionTypes"

export const getPaymentHistory = (page,startDate,endDate) => ({
  type: GET_PAYMENT_HISTORY,
  payload: {page,startDate,endDate},
})

export const getPaymentHistorySuccess = orders => ({
  type: GET_PAYMENT_HISTORY_SUCCESS,
  payload: orders,
})

export const getPaymentHistoryFail = error => ({
  type: GET_PAYMENT_HISTORY_FAIL,
  payload: error,
})
