import {
  GET_TAGS,
  GET_TAGS_FAIL,
  GET_TAGS_SUCCESS,
  ADD_NEW_TAG,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  GET_DAYS,
  GET_DAYS_SUCCESS,
  GET_DAYS_FAIL,
  ADD_NEW_DAY,
  ADD_NEW_DAY_SUCCESS,
  ADD_NEW_DAY_FAIL,
  UPDATE_DAY,
  UPDATE_DAY_SUCCESS,
  UPDATE_DAY_FAIL,
  DELETE_DAY,
  DELETE_DAY_SUCCESS,
  DELETE_DAY_FAIL,
} from "./actionTypes"

// Tags
export const getTags = (filter) => ({
  type: GET_TAGS,
  payload: filter,
})

export const getTagsSuccess = tags => ({
  type: GET_TAGS_SUCCESS,
  payload: tags,
})

export const getTagsFail = error => ({
  type: GET_TAGS_FAIL,
  payload: error,
})

export const addNewTag = category => ({
  type: ADD_NEW_TAG,
  payload: category,
})

export const addTagSuccess = category => ({
  type: ADD_TAG_SUCCESS,
  payload: category,
})

export const addTagFail = error => ({
  type: ADD_TAG_FAIL,
  payload: error,
})

export const updateTag = category => ({
  type: UPDATE_TAG,
  payload: category,
})

export const updateTagSuccess = category => ({
  type: UPDATE_TAG_SUCCESS,
  payload: category,
})

export const updateTagFail = error => ({
  type: UPDATE_TAG_FAIL,
  payload: error,
})

export const deleteTag = category => ({
  type: DELETE_TAG,
  payload: category,
})

export const deleteTagSuccess = category => ({
  type: DELETE_TAG_SUCCESS,
  payload: category,
})

export const deleteTagFail = error => ({
  type: DELETE_TAG_FAIL,
  payload: error,
})

// Day Table
export const getDays = (filter) => ({
  type: GET_DAYS,
  payload: filter,
})

export const getDaysSuccess = days => ({
  type: GET_DAYS_SUCCESS,
  payload: days,
})

export const getDaysFail = error => ({
  type: GET_DAYS_FAIL,
  payload: error,
})

export const addNewDay = category => ({
  type: ADD_NEW_DAY,
  payload: category,
})

export const addDaySuccess = category => ({
  type: ADD_NEW_DAY_SUCCESS,
  payload: category,
})

export const addDayFail = error => ({
  type: ADD_NEW_DAY_FAIL,
  payload: error,
})

export const updateDay = category => ({
  type: UPDATE_DAY,
  payload: category,
})

export const updateDaySuccess = category => ({
  type: UPDATE_DAY_SUCCESS,
  payload: category,
})

export const updateDayFail = error => ({
  type: UPDATE_DAY_FAIL,
  payload: error,
})

export const deleteDay = category => ({
  type: DELETE_DAY,
  payload: category,
})

export const deleteDaySuccess = category => ({
  type: DELETE_DAY_SUCCESS,
  payload: category,
})

export const deleteDayFail = error => ({
  type: DELETE_DAY_FAIL,
  payload: error,
})